import React, { useEffect } from 'react';
import * as d3 from 'd3';

function ChartBoxPlotMeteorology({ containerId, meteorologyData, meteorology, timeInterval }) {
  function formatDate(date, timeInterval) {
    switch (timeInterval) {
      case 'hour':
        return d3.timeFormat("%H:%M")(date);
      case 'day':
        return d3.timeFormat("%d/%m %H:%M")(date);
      case 'week':
        return d3.timeFormat("%a %d/%m")(date);
      case 'month':
      case '3months':
      case '6months':
      case 'year':
        return d3.timeFormat("%d/%m/%Y")(date);
      default:
        return d3.timeFormat("%d/%m/%Y %H:%M")(date);
    }
  }

  function getBarWidth(interval) {
    switch (interval) {
      case 'hour':
        return 30;
      case 'day':
        return 15;
      case 'week':
        return 25;
      case 'month':
        return 15;
      case '3months':
      case '6months':
      case 'year':
        return 25;
      default:
        return 15;
    }
  }

  useEffect(() => {
    if (meteorologyData && Object.keys(meteorologyData).length > 0) {
      createChart(containerId, meteorologyData, meteorology, timeInterval);
    }
  }, [meteorologyData, meteorology, timeInterval]);

  if (!meteorologyData || Object.keys(meteorologyData).length === 0) {
    return null;
  }

  function createChart(containerId, meteorologyData, meteorology, timeInterval) {
    const colorMap = {temperature: "#ff9999", 
                      humidity: "lightsteelblue", 
                      solar_radiation: "papayawhip", 
                      precipitation: "lightblue",
                      wind_speed: "aliceblue",
                      wind_direction: "lavender",
                      pressure: "wheat",
                    };
    const color = colorMap[meteorology] || "lightsteelblue";

    const yAxisTitleMap = { temperature: "Temperature (°C)", 
                            humidity: "Humidity (%)", 
                            solar_radiation: "Solar Radiation (W/m²)", 
                            precipitation: "Precipitation (mm)",
                            wind_speed: "Wind speed (m/s)", 
                            wind_direction: "Wind direction (º)",
                            pressure: "Atmospheric Pressure (hPa)", 
                          };
    const yAxisTitle = yAxisTitleMap[meteorology] || "Value";

    const unitsMap =  { temperature: "°C", 
                        humidity: "%", 
                        solar_radiation: "W/m²", 
                        precipitation: "mm",
                        wind_speed: "m/s", 
                        wind_direction: "º",
                        pressure: "hPa", 
                      };
    const units = unitsMap[meteorology] || "";

    const margin = { top: 35, right: 20, bottom: 85, left: 60 },
          width = 1100 - margin.left - margin.right,
          height = 500 - margin.top - margin.bottom;

    d3.select(`#${containerId}`).selectAll("*").remove();

    const svg = d3.select(`#${containerId}`)
      .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
      .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

    const data = Object.keys(meteorologyData).map((key) => ({
      date: new Date(key),
      ...meteorologyData[key],
    }));

    const x = d3.scaleBand()
      .range([0, width])
      .domain(data.map(d => d.date))
      .paddingInner(1)
      .paddingOuter(.5);

    const y = d3.scaleLinear()
      .domain([0, d3.max(data, d => d.maximum)])
      .range([height, 0]);

    svg.append("g")
       .attr("transform", `translate(0, ${height})`)
       .call(d3.axisBottom(x).tickFormat(d => formatDate(d, timeInterval)).tickValues(data.map(d => d.date)))
       .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-65)");

    svg.append("g")
       .call(d3.axisLeft(y));

    svg.append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - margin.left)
      .attr("x", 0 - (height / 2))
      .attr("dy", "2em")
      .style("text-anchor", "middle")
      .style("font-size", "12px")
      .style("fill", "#696969")
      .text(yAxisTitle);

      
    // Tooltip
    const tooltip = d3.select(`#${containerId}`).append("div")
                      .style("opacity", 0)
                      .attr("class", "tooltip")
                      .style("background-color", "white")
                      .style("border", "solid")
                      .style("border-width", "2px")
                      .style("border-radius", "5px")
                      .style("padding", "5px")
                      .style("position", "absolute")
                      .style("align-items", "left")
                      .style("text-align", "left");

    const showTooltip = (event, date, median, minimum, maximum, percentile25, percentile75, timeInterval) => {
      const tooltipEstimatedWidth = 70;
      const tooltipEstimatedHeight = 70;
      
      let baseX = x(date);
      let baseY = y(median);

      let tooltipX = baseX + getBarWidth(timeInterval) / 2 + tooltipEstimatedWidth;
      let tooltipY = baseY + tooltipEstimatedHeight; 


      if(tooltipX + tooltipEstimatedWidth > width) tooltipX = baseX - getBarWidth(timeInterval) - tooltipEstimatedWidth - 60;

      tooltip
        .style("opacity", 1)
        .html(`<b>Date:</b> ${formatDate(date, timeInterval)}<br>
              <b>Median:</b> ${median != null ? median.toFixed(2) : 'N/A'} ${units}<br>
              <b>Min:</b> ${minimum != null ? minimum.toFixed(2) : 'N/A'} ${units}<br>
              <b>Max:</b> ${maximum != null ? maximum.toFixed(2) : 'N/A'} ${units}<br>
              <b>25th Percentile:</b> ${percentile25 != null ? percentile25.toFixed(2) : 'N/A'} ${units}<br>
              <b>75th Percentile:</b> ${percentile75 != null ? percentile75.toFixed(2) : 'N/A'} ${units}`)
        .style("left", `${tooltipX}px`)
        .style("top", `${tooltipY}px`);
    };

    const hideTooltip = () => {
      tooltip.transition().duration(200).style("opacity", 0);
    };

    const barWidth = getBarWidth(timeInterval);

    // Adding boxplots
    data.forEach(d => {
      const date = d.date;
      const median = d.median;
      const minimum = d.minimum;
      const maximum = d.maximum;
      const percentile25 = d.percentile25;
      const percentile75 = d.percentile75;

      // Line from min to max
      svg.append("line")
         .attr("x1", x(d.date))
         .attr("x2", x(d.date))
         .attr("y1", y(d.minimum))
         .attr("y2", y(d.maximum))
         .attr("stroke", "black")
         .style("cursor", "pointer")
         .on("mouseover", (event, d) => showTooltip(event, date, median, minimum, maximum, percentile25, percentile75, timeInterval))
         .on("mousemove", (event, d) => showTooltip(event, date, median, minimum, maximum, percentile25, percentile75, timeInterval))
         .on("mouseleave", hideTooltip);

      // Box
      svg.append("rect")
         .attr("x", x(d.date) - barWidth / 2)
         .attr("y", y(d.percentile75))
         .attr("height", y(d.percentile25) - y(d.percentile75))
         .attr("width", barWidth)
         .attr("stroke", "black")
         .style("fill", color)
         .style("cursor", "pointer")
         .on("mouseover", (event, d) => showTooltip(event, date, median, minimum, maximum, percentile25, percentile75, timeInterval))
         .on("mousemove", (event, d) => showTooltip(event, date, median, minimum, maximum, percentile25, percentile75, timeInterval))
         .on("mouseleave", hideTooltip);

      // Median line
      svg.append("line")
         .attr("x1", x(d.date) - barWidth / 2)
         .attr("x2", x(d.date) + barWidth / 2)
         .attr("y1", y(d.median))
         .attr("y2", y(d.median))
         .attr("stroke", "black")
         .style("cursor", "pointer")
         .on("mouseover", (event, d) => showTooltip(event, date, median, minimum, maximum, percentile25, percentile75))
         .on("mousemove", (event, d) => showTooltip(event, date, median, minimum, maximum, percentile25, percentile75))
         .on("mouseleave", hideTooltip);

      // Min and Max lines
      const linePadding = barWidth / 2;
      svg.append("line")
          .attr("x1", x(d.date) - linePadding)
          .attr("x2", x(d.date) + linePadding)
          .attr("y1", y(d.minimum))
          .attr("y2", y(d.minimum))
          .attr("stroke", "black")
          .style("cursor", "pointer");


      svg.append("line")
          .attr("x1", x(d.date) - linePadding)
          .attr("x2", x(d.date) + linePadding)
          .attr("y1", y(d.maximum))
          .attr("y2", y(d.maximum))
          .attr("stroke", "black")
          .style("cursor", "pointer");

    });
  
  }
  
  return <div id={containerId} style={{ height: "520px", width: "1000px"}}></div>;
}

export default ChartBoxPlotMeteorology;
