import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import {AuthProvider} from './components/Auth/AuthContext';
import {configureStore} from '@reduxjs/toolkit';
import collapsedReducer from './features/CollapsedSlice';
import authRecuder from './features/AuthSlice';
import Application from './app';
import './styles/index.css';

const store = configureStore({
  reducer: {
    collapsed: collapsedReducer,
    auth: authRecuder
  },
});

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <Provider store={store}> 
        <Application/>
      </Provider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
