import React from 'react';
import { IoCarSharp, IoBicycleSharp } from 'react-icons/io5'; // Import your icon components
import { FaMotorcycle } from "react-icons/fa";
import { HiOutlineTruck } from "react-icons/hi";
import { PiTruckBold } from "react-icons/pi";
import { BsBusFrontFill } from "react-icons/bs";
import { BiWalk, BiSolidDog } from "react-icons/bi";

function ObjectIcon({ subClassName }) {
  const iconMap = {
    // Vehicle:
    motorcycle: <FaMotorcycle size={20} color="black" display="block" margin-left= "auto" margin-right= "auto" />,
    passengerCar: <IoCarSharp size={20} color="black" display="block" margin-left= "auto" margin-right= "auto" />,
    lightTruck: <HiOutlineTruck size={20} color="black" display="block" margin-left= "auto" margin-right= "auto" />,
    heavyTruck: <PiTruckBold size={20} color="black" display="block" margin-left= "auto" margin-right= "auto" />,
    bus: <BsBusFrontFill size={20} color="black" display="block" margin-left= "auto" margin-right= "auto" />,
    lightVRUvehicle:  <IoBicycleSharp size={20} color="black" display="block" margin-left= "auto" margin-right= "auto" />,
    // Person:
    pedestrian: <BiWalk size={20} color="black" display="block" margin-left= "auto" margin-right= "auto" />,
    cyclist: <IoBicycleSharp size={20} color="black" display="block" margin-left= "auto" margin-right= "auto" />,
    animal: <BiSolidDog size={20} color="black" display="block" margin-left= "auto" margin-right= "auto" />,
    //Unknown Type:
    unknown: <IoCarSharp size={20} color="black" display="block" margin-left= "auto" margin-right= "auto" />,
    unknownP: <BiWalk size={20} color="black" display="block" margin-left= "auto" margin-right= "auto" />,
  };

  const selectedIcon = iconMap[subClassName] || iconMap.passengerCar;

  return (
    <div>
      {selectedIcon}
    </div>
  );
}

export default ObjectIcon;