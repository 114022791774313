import React, { useEffect } from 'react';
import '../styles/generic_menu.css';
import '../styles/constants.css';
import v2x_background from '../static/background2.jpg';
import pasmo from '../static/pasmo.png';
import steroid from '../static/steroid.svg';
import atobe from '../static/AtoBe.png';
import route25 from '../static/route25_2.png';

const About_Page = () => {
  const handleScroll = () => {
    window.scrollTo({
      top: window.scrollY + 1,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="scrollable-background" style={{ backgroundImage: `url(${v2x_background})` }}>
      <div className="white-box-content large-info">
        <h1 className="title1-text mb-15">About CCAM</h1>
        <p className="paragraph-text mb-15">
          Cooperative, Connected and Automated Mobility (CCAM) is a new approach to transportation that combines advanced
          communication technologies, automated driving systems, and cooperative networking to create a safe, efficient, and
          sustainable transportation system. Cooperative technologies allow vehicles to communicate with each other and with the
          transportation infrastructure, sharing information such as traffic conditions and road hazards.
        </p>

        <h1 className="title1-text mb-15">Implementation</h1>
        <p className="paragraph-text mb-15">
          This project is built on the latest research in the field of V2X communications and smart transportation. For more
          information, please check the following article:{' '}
          <a href="https://www.mdpi.com/2078-2489/14/3/153" target="_blank" rel="noopener noreferrer">
            "A Roadside and Cloud-Based Vehicular Communications Framework for the Provision of C-ITS Services"
          </a>
        </p>

        <h1 className="title1-text mb-15">Mobile App</h1>
        <p className="paragraph-text mb-15">
          Connected technologies also allow vehicles to connect with other devices and networks, such as smartphones, to provide
          additional services and information to drivers, passengers, and pedestrians. We also provide a mobile app that shares
          this purpose. It is available at{' '}
          <a href="https://play.google.com/store/apps/details?id=com.it2s.it2smobileapp&hl=pt_PT&pli=1" target="_blank" rel="noopener noreferrer">
            Google Play Store
          </a>.
        </p>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '40px' }}>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
            <div className="logo-wrapper">
              <a className="logo-href" href="https://route-25.com/" target="_blank" rel="noopener noreferrer">
                <img src={route25} alt="Route25" className="logo-img" />
              </a>
            </div>
            <div className="logo-wrapper">
              <a className="logo-href" href="https://steroid-project.pt/pt/home-portugues/" target="_blank" rel="noopener noreferrer">
                <img src={steroid} alt="Steroid" className="logo-img" />
              </a>
            </div>
            <div className="logo-wrapper">
              <a className="logo-href" href="https://pasmo.pt/project/pasmo" target="_blank" rel="noopener noreferrer">
                <img src={pasmo} alt="Pasmo" className="logo-img" />
              </a>
            </div>
            <div className="logo-wrapper">
              <a className="logo-href" href="https://www.a-to-be.com/" target="_blank" rel="noopener noreferrer">
                <img src={atobe} alt="AtoBe" className="logo-img" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About_Page;
