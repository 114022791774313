import React from "react";
import PropTypes from "prop-types";

const YoutubeEmbed = ({ video }) => (
  <div className="video-responsive">
    <iframe
      src={`https://www.youtube.com/embed/${video.id.videoId}`}
      width="250"
      height="130"
      frameBorder="0"
      title={video.snippet.title}
    />
  </div>
);

YoutubeEmbed.propTypes = {
  video: PropTypes.object.isRequired
};

export default YoutubeEmbed;