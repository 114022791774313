import styled from "styled-components";

const WrapperDiv = styled.div`
    padding: .5rem 1rem;
    display: flex;
    align-items: center;
    color: black !important;
    background-color: ${props => props.selected ? 'lightgrey' : 'inherit'};
    &:hover {
        background-color: grey;
    }
    cursor: pointer;
` 

function DashboardsList(props) {
    return(
        <WrapperDiv onClick={props.onClick} selected={props.selected}>
             <span>{props.type} {props.k}: {props.data}</span>  
        </WrapperDiv>
    )
}

export default DashboardsList;