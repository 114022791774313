import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import axios from 'axios';

function WsuMessageList({ wsu_id }) {
    const [messages, setMessages] = useState([]);
    const [expandedIndexes, setExpandedIndexes] = useState([]);

    useEffect(() => {
      async function getHeartbeats() {
        try {
          const response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_PREFIX}/wsu/${wsu_id}/message`,
            headers: {
              Authorization: process.env.REACT_APP_API_PUBLIC_TOKEN,
            }
          });

          const responseData = response.data;

          const manipulatedData = responseData.message.map(item => ({
            generationDateTime: (item.generationDateTime  + 1072915200000 - 5000),
            temperature: (item.temperature),
            humidity: (item.humidity),
            wind_speed: (item.wind_speed),
            wind_direction: (item.wind_direction),
            solar_radiation: (item.solar_radiation),
            atmospheric_pressure: (item.atmospheric_pressure)
          }));

          setMessages(manipulatedData);

          if (Array.isArray(manipulatedData) && manipulatedData.length > 0) {
            const initialExpandedState = Array(manipulatedData.length).fill(
              false
            );
            setExpandedIndexes(initialExpandedState);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }

      getHeartbeats();
    }, [wsu_id]);

    const toggleExpand = index => {
      const updatedExpandedIndexes = [...expandedIndexes];
      updatedExpandedIndexes[index] = !updatedExpandedIndexes[index];
      setExpandedIndexes(updatedExpandedIndexes);
    };

    function parseDate(obj) {
      const date = new Intl.DateTimeFormat('pt-PT', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
      }).format(obj)
      return date
    }

    function getCardinalDirection(degrees) {
        const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
        degrees = (degrees + 360) % 360; // Ensure degrees is positive
        const index = Math.round(degrees / 45) % 8;
        return directions[index];
      }

    return (
      <div>
        {messages?.length > 0 ? (
          messages.map((message, index) => (
            <MessageContainer
              key={index}
              highlighted={expandedIndexes[index]}
              onClick={() => toggleExpand(index)}
            >
              <div>
                <b>Message #{index}</b>
                <br />
                <b>Generation Date Time: </b>{parseDate(message.generationDateTime)}
              </div>
              {expandedIndexes[index] && (
                <div>
                  <b>Temperature: </b>{message.temperature} ºC
                  <br />
                  <b>Humidity: </b>{message.humidity} %
                  <br />
                  <b>Wind Speed: </b>{message.wind_speed} m/s
                  <br />
                  <b>Wind Direction: </b>{getCardinalDirection(message.wind_direction)}
                  <br />
                  <b>Solar Radiation: </b>{message.solar_radiation} W/m²
                  <br />
                  <b>Atmos. Pressure: </b>{message.atmospheric_pressure} hPa
                </div>
              )}
            </MessageContainer>
          ))
        ) : (
          <div>No messages available.</div>
        )}
      </div>
    );
}

const MessageContainer = styled.div`
  padding: 10px;
  border: 1px solid #ccc;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  animation: ${props => (props.highlighted ? pulseAnimation : 'none')};
  background-color: ${props => (props.highlighted ? '#f0f0f0' : 'initial')};
  text-align: left;

  &:hover {
    animation: pulseAnimation 1.5s infinite alternate;
    background-color: #f0f0f0;
  }
`;

// Define the pulse animation using keyframes
const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
`;


export default WsuMessageList;


