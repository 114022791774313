import React, { useState, useEffect } from "react";
import '../styles/generic_menu.css';
import '../styles/constants.css';
import axios from "axios";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { useContext } from 'react';
import { AuthContext } from '../components/Auth/AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { sha256 } from 'js-sha256';
import v2x_background from '../static/background2.jpg';

const Login = () => {
  function notify(message, type) {
    if (type === "success")
      toast.success(message, {
        position: toast.POSITION.BOTTOM_CENTER
    });
    else if (type === "error")
      toast.error(message, {
        position: toast.POSITION.BOTTOM_CENTER
    });
    else 
      toast.info(message, {
        position: toast.POSITION.BOTTOM_CENTER
    });
  }

  useEffect(() => {
    notify("Please login with your admin credentials.");
  }, []);

  const { setIsAuthenticated } = useContext(AuthContext);
  const history = useHistory();
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (evt) => {
    if (evt) {
      evt.preventDefault();
    }

    const hashedPassword = sha256(password);

    const data = {
      username: name,
      password: hashedPassword,
    };
    
    const news = async () => {
      let res = await axios
        .post(`${process.env.REACT_APP_AUTH_PREFIX}/login`, data)
        .then((response) => {
          Cookies.set('token', response.data.access_token);
          const isAdmin = response.data.is_admin;
          if(isAdmin) setIsAuthenticated(true)
          return response;
        })
        .catch((error) => {
          notify("Invalid username or password", "error");
          console.log("[error] login: " +error.message);
        });
      return res;
    };
    let x = await news();
    if (x) {
      notify("Logged in successfully!", "success");
      history.push("/map");
    }
  };
  return (
    <div className="scrollable-background flex-aligned" style={{ backgroundImage: `url(${v2x_background})`}}>
    <div className="white-box-content small-info">
        <form onSubmit={handleSubmit} className="login-form">
            <label>
                <h6 class="title6-text mb-30">Username</h6>
                <input className="mb-30 text-input"
                    required={true}
                    type="name"
                    onChange={(e) => setName(e.target.value)}
                />
            </label>
            <label>
                <h6 class="title6-text mb-30">Password</h6>
                <input className="mb-30 text-input"
                    required={true}
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                />
            </label>
            <div>
                <button type="submit" className="blue-button">
                Sign in
                </button>
            </div>
        </form>
    </div>
  </div>
  );
};

export default Login;
