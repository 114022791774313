import styled from 'styled-components'

export const TooltipText = styled.span`
    visibility: hidden;
    background-color: black;
    width: 220px;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -5px;
    left: 20px;
    font-size: 12px;

::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
}
`

export const Tooltip = styled.div`
    position: relative;
    display: inline-block;
    text-align: left;

&:hover {
    cursor: pointer;

    ${TooltipText} { 
        visibility: visible;
    }
}
`