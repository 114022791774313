import { useState, useEffect } from "react";
import Cookies from 'js-cookie';
const JSMpeg = require('@cycjimmy/jsmpeg-player');

function CameraStream(props) {
    const [port, setPort] = useState('')
    const [player, setPlayer] = useState(false)

    useEffect(() => {
        async function getCameraPortN() {
            setPort(null)
            
            if (player) {
                player.destroy()
                setPlayer(null)
            }

            const port = 9000 + props.rsu_id;
            const token = Cookies.get('token');
            try {
                const response = await fetch(`${process.env.REACT_APP_API_PREFIX}/camera-stream/${props.rsu_id}`, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                });
            
                if (!response.ok) {
                    throw new Error('Not authorized');
                }

                setPort(port)
                var videoUrl = `${process.env.REACT_APP_STREAM_PREFIX}/stream/${props.rsu_id}/`;
                console.log("[info] stream URL: " + videoUrl);
                setPlayer(new JSMpeg.VideoElement("#video-canvas", videoUrl, {
                    autoplay: true,
                }))
            } catch (e) {
                console.log("[error] connecting to stream:" +e);
                setPort(null)
            }
        }
        getCameraPortN()
    }, [props.rsu_id])

    return (
        <div id="body">
            {!port ? 'The camera stream does not exists' : (<div id="video-canvas" style={{ aspectRatio: "16/9", width: "100%", marginTop: "15px", marginLeft: "4px" }}></div>)}
        </div>
    )
}

export default CameraStream;
