import styled from "styled-components";

export const RsuIDInfoDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const WsuIDInfoDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const RsuTabIcon = styled.div`
  cursor: pointer;
  margin-left: auto;
  transform: ${(props) =>
    props.collapsed ? "rotate(0deg)" : "rotate(180deg)"};
`;

export const CreateAlertTabIcon = styled.div`
  cursor: pointer;
  margin-left: auto;
  transform: ${(props) =>
    props.collapsed ? "rotate(0deg)" : "rotate(180deg)"};
`;

export const DisplayAlertTabIcon = styled.div`
  cursor: pointer;
  margin-left: auto;
  transform: ${(props) =>
    props.collapsed ? "rotate(0deg)" : "rotate(180deg)"};
`;

export const SelectTabRel = styled.div`
  position: relative;
  overflow: auto;
  background-color: white;
  border-radius: 8px;
  outline-style: solid;
  outline-width: 2px;
  outline-color: black;
  margin-bottom: 8px;
  margin-top: 4px;

  animation: growDown 200ms ease-in-out forwards;
  transform-origin: top;
  @keyframes growDown {
    0% {
        transform: scaleY(0)
    }
    100% {
        transform: scaleY(1)
    }
  }
`;


export const StationsTabRel = styled.div`
  position: relative;
  max-height:250px;
  overflow-y: auto;
  background-color: white;
  border-radius: 8px;
  outline-style: solid;
  outline-width: 2px;
  outline-color: black;
  margin-bottom: 8px;
  margin-top: 4px;

  animation: growDown 200ms ease-in-out forwards;
  transform-origin: top;
  @keyframes growDown {
    0% {
        transform: scaleY(0)
    }
    100% {
        transform: scaleY(1)
    }
  }
`;

export const RsuMainDiv = styled.div`
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

export const RsusTabInfoDiv = styled.div`
  width: 540px;
  height: ${props => (props.isAuthenticated ? '395px' : '340px')};
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  overflow: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  outline-style: solid;
  outline-width: 2px;
  outline-color: black;

  @media (max-width: 768px) {
    width: 270px;
    height: ${props => (props.isAuthenticated ? '340px' : '300px')};
  }
`;

export const WsusTabInfoDiv = styled.div`
  width: 540px;
  height: 380px;
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  overflow: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  outline-style: solid;
  outline-width: 2px;
  outline-color: black;

  @media (max-width: 768px) {
    width: 270px;
  }
`;

export const StatisticsDiv = styled.div`
  position: absolute;
  margin-top: 10px;
  height: fit-content;
  top: 0;
  right: 0;
  width: 1100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid;
  border-radius: 4px;
  background-color: white;
  z-index: 2;
  outline-style: solid;
  outline-width: 1px;
  outline-color: black;
  margin-right: 32px;
`;


export const WeatherStatisticsDiv = styled.div`
  position: absolute;
  margin-top: 10px;
  height: fit-content;
  top: 0;
  right: 10px;
  width: 1100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid;
  border-radius: 4px;
  background-color: white;
  z-index: 2;
  outline-style: solid;
  outline-width: 1px;
  outline-color: black;
  margin-right: 32px;
`;

export const HeaderStatistics = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 40px;
  background-color: #2b2b2b; // dark grey
  color: lightgrey !important;
`;

export const CalendarHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 40px;
  background-color: white;
`;

export const BoxCharts = styled.div`
  /* position: relative; */
  background-color: #dbeafd;
  flex-direction: column;
  width: 100%;
  height: 100%;
  grid-gap: 1.5rem;
  gap: 1.5rem;
`;
export const HeaderContainerChart = styled.div`
  position: relative;
  .compareButton {
    border-radius: 4px;
    background-color: white;
    color: black;
    border: 2px solid #338afa;
    margin-left: 30px;
    top: 0;
    bottom: 0;
    &:hover {
      cursor: pointer;
      background-color: #019dc9;
      color: white;
    }
  }
`;

export const ArrowCloseOpen = styled.div`
  position: absolute;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  top: 50%;
  margin: 0;
  height: 50px;
  width: 50px;
  right: ${(props) => (props.collapsed ? "calc(560px)" : "10px")};
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  transform: ${(props) => props.collapsed ? "rotate(180deg)" : "rotate(0deg)"};
  color: black;
  @media (max-width: 768px) {
    right: ${(props) => (props.collapsed ? "calc(295px)" : "10px")};
  }
  z-index: 100;
`;

export const RsusMoreInfoDiv = styled.div`
  position: relative;
  width: 100%;
  background-color: white;
  border: 1px solid;
  padding: 5px;
  border-radius: 5px;
  overflow: hidden;
`;

export const RightTab = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  transition: left 0.3s ease;
  top: 10px;
  width: 280px;
  height: auto;
  z-index: 100;
  @media (min-width: 769px) {
    left: 10px;
  }
  @media (max-width: 768px) {
    right: 10px;
  }
`;

export const RightTabHeaderDiv = styled.div`
  cursor: pointer;
  display: flex;
  outline-style: solid;
  outline-width: 2px;
  outline-color: black;
  margin-bottom: 8px;
  background-color: #2b2b2b; // dark grey
  align-items: center;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  border-radius: 8px;
  color: lightgrey !important;
  &:not(:first-of-type) {
    margin-top: 5px;
  }
  & > span {
    padding: 0.75rem 1rem;
  }
  & > div {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  &:hover {
    background-color: #404040; 
  }  
  transition: background-color 0.2s ease-in-out;
`;

export const RightTabHeaderDivHiddenOnMobile = styled.div`
  cursor: pointer;
  display: flex;
  outline-style: solid;
  outline-width: 2px;
  outline-color: black;
  margin-bottom: 8px;
  background-color: #2b2b2b; // dark grey
  align-items: center;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  border-radius: 8px;
  color: lightgrey !important;
  &:not(:first-of-type) {
    margin-top: 5px;
  }
  & > span {
    padding: 0.75rem 1rem;
  }
  & > div {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  &:hover {
    background-color: #404040; 
  }  
  transition: background-color 0.2s ease-in-out;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Loader = styled.div`
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  margin: auto;
  margin-top: 5px;
  margin-bottom: 5px;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Button = styled.div`
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #3498db;
  border: 2px solid #3498db;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2980b9;
    border-color: #2980b9;
  }
`;

export const ChartsContainer = styled.div`
    width: 100%;
    height: 100%;
  `;

export const RadioLine = styled.div`
  stroke-dasharray: 10;
  stroke-dashoffset: 100;
  animation: flow 3s linear infinite;
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);

  @keyframes flow {
    100% {
      stroke-dashoffset: 0;
    }
  }
`;

export const ToggleContainer = styled.div`
    position: absolute;
    bottom: 20px;
    right: 5px;
    padding: 10px;
    background: white;
    border-radius: 5px;
    display: flex;
    align-items: center;
`;

export const Label = styled.span`
    margin: 0 10px;
`;

export const PopupContent = styled.div`
  padding: 10px;
  width: 400px;
  max-height:350px;
  overflow-y: auto;
`;

export const PopupTitle = styled.h3`
  font-size: 20px;
  margin-bottom: 10px; 
`;

export const PopupList = styled.ul`
  padding-left: 20px;
`;

export const PopupListItem = styled.li`
  margin-bottom: 5px;
  text-align: left;
  font-size: 16px;
`;