import React from 'react';
import styled from 'styled-components';

const PopupContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
  &:before {
    content: '×';
  }
`;

const Content = styled.div`
  text-align: left;
`;

function TumPopup({ isOpen, onClose, tumData }) {
  if (!isOpen) return null;

  return (
    <>
      <PopupOverlay onClick={onClose} />
      <PopupContainer>
        <CloseButton onClick={onClose} />
        <br></br><h5><b>ID:</b> {tumData.stationId}</h5><br></br>
        <Content>
          <p><b>Latitude:</b> {tumData.latitude}</p>
          <p><b>Longitude:</b> {tumData.longitude}</p>
          <p><b>Vehicle Type:</b> {tumData.vehicleType}</p>
          <p><b>Charge:</b> {tumData.charge} €</p>
        </Content>
      </PopupContainer>
    </>
  );
}

export default TumPopup;
