
import { createContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isUser, setIsUser] = useState(false);

  const checkAuthentication = async () => {
    const token = Cookies.get('token');
  
    try {
      const response = await fetch(`${process.env.REACT_APP_AUTH_PREFIX}/users/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Not authorized');
      }
  
      const responseData = await response.json();
      const isAdmin = responseData.is_admin;
  
      if (isAdmin) {
        setIsAuthenticated(true);
        setIsUser(false);
      } else {
        setIsAuthenticated(false);
        setIsUser(true);
      }
  
      return true;
    } catch (error) {
      setIsAuthenticated(false);
      setIsUser(false);
      return false;
    }
  };

  // Clear the token cookie and set isAuthenticated/isUser to false on logout
  const logout = async () => {
    const token = Cookies.get('token');
    // console.log('Token: ' + token);
    
    try {
      const response = await fetch(`${process.env.REACT_APP_AUTH_PREFIX}/logout`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // credentials: 'include',
      });
  
      if (!response.ok) {
        console.error('Logout failed:', response.statusText);
        return false;  // Logout failed
      }
  
      // Logout was successful, update the state and remove the token
      setIsAuthenticated(false);
      setIsUser(false);
      Cookies.remove("token");
      return true;  // Logout successful
    } catch (error) {
      console.error('Logout failed:', error);
      return false;  // Logout failed due to a network error or unexpected issue
    }
  };
  
  useEffect(() => {
    checkAuthentication();
  }, []);


  const value = { isAuthenticated, setIsAuthenticated, isUser, setIsUser, logout, checkAuthentication };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
