import styled from "styled-components";
import Switch from "react-switch";
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../Auth/AuthContext';

const CircleStatus = styled.div`
    background-color: ${props => props.status};
    display: flex;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-right: 14px;
`
const WrapperDiv = styled.div`
    padding: .5rem 1rem;
    display: flex;
    align-items: center;
    color: black !important;
    background-color: ${props => props.selected ? 'lightgrey' : 'inherit'};
    text-decoration: ${props => !props.active ? 'line-through' : 'none'};
    &:hover {
        background-color: grey;
    }
    cursor: pointer;
    label {
        display: flex;
        align-items: center;
        margin-left: auto;
    }
` 
function RsuBar(props) {
    const { isAuthenticated } = useContext(AuthContext);

    const [isOn, setIsOn] = useState(props.active);

    // Function to toggle the switch state
    const toggleSwitch = () => {
        const invertedState = !isOn;
        setIsOn(invertedState);
        props.toggleRsu(props._id, invertedState);
    };

    useEffect(() => {
        setIsOn(props.active);
    }, [props.active]);

    return(
        <WrapperDiv onClick={props.onClick} active={props.active} selected={props.selected}>
            <CircleStatus status={props.status} />
            <span>Station ID: {props._id}</span>  
            {isAuthenticated && (
                <label>
                    {/* <b style={{ marginRight: '8px' }}>Visibility:</b> */}
                    <Switch
                        onChange={toggleSwitch}
                        checked={isOn}
                        height={20}
                        width={40}
                        offColor={'#FF0000'}
                        borderRadius={10}
                    />
                </label>
            )}
        </WrapperDiv>
    )
}

export default RsuBar;