import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Dropdown, InputGroup } from 'rsuite';
import 'rsuite/dist/rsuite.min.css'; 

const GenericDropdown = ({label, options, onSelectChange, selected, resetDropdown, isRequired = false}) => {
  const [selectedOption, setSelectedOption] = useState(options[0] || "");

  useEffect(() => {
    // Update internal state if the external selected prop changes
    setSelectedOption(selected || options[0]);
  }, [selected, options]);

  useEffect(() => {
    // Reset the dropdown when resetDropdown changes
    if (resetDropdown) setSelectedOption(options[0] || "");
  }, [resetDropdown, options]);

  const handleSelectChange = (value) => {
    setSelectedOption(value);
    onSelectChange(value);
  };

  return (
    <Container>
      <InputGroup>
        {label && (
          <InputGroup.Addon>
            <Label required={isRequired}>
            {isRequired ? '*' : ''}{label}:
            </Label>
          </InputGroup.Addon>
        )}
        {/* { label ? <InputGroup.Addon>{label}: </InputGroup.Addon> : null } */}
        <Dropdown title={selectedOption || "Select an option"} onSelect={handleSelectChange}> 
            {options.map(option => (
            <Dropdown.Item key={option} eventKey={option}>{option}</Dropdown.Item>
            ))}
        </Dropdown>
      </InputGroup>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  font-size: 14px;
  ${props => props.required && css`
    color: black; 
  `}
`;

export default GenericDropdown;
