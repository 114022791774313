import React from 'react';
import { Chart, CategoryScale, LinearScale, Title, Tooltip, Legend, BarController, BarElement } from 'chart.js';
import { Bar } from 'react-chartjs-2';

Chart.register(CategoryScale, LinearScale, Title, Tooltip, Legend, BarController, BarElement);

// function formatDate(dateString) {
//     const date = new Date(dateString);
//     const day = date.getDate().toString().padStart(2, '0');
//     const month = (date.getMonth() + 1).toString().padStart(2, '0'); // JavaScript months are 0-based.
//     const year = date.getFullYear();
//     const hours = date.getHours().toString().padStart(2, '0');
//     const minutes = date.getMinutes().toString().padStart(2, '0');
//     return `${day}/${month}/${year} ${hours}:${minutes}`;
// }

function formatDate(date, timeInterval) {
  switch (timeInterval) {
      case 'hour':
          return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
      case 'day':
          return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
      case 'week':
          return `${date.toLocaleDateString('en-US', { weekday: 'short' })} ${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}`;
      case 'month':
      case '3months':
      case '6months':
      case 'year':
          return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
      default:
          const day = date.getUTCDate().toString().padStart(2, '0');
          const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
          const year = date.getUTCFullYear();
          const hours = date.getUTCHours().toString().padStart(2, '0');
          const minutes = date.getUTCMinutes().toString().padStart(2, '0');
          return `${day}/${month}/${year} ${hours}:${minutes}`;
  }
}

function ChartSpeed({ speedData, timeInterval }) {
  if (!speedData) {
    return null;
  }

  const labels = Object.keys(speedData);

  const minSpeedData = labels.map(label => speedData[label]?.minimum || 0);
  const avgSpeedData = labels.map(label => speedData[label]?.average || 0);
  const maxSpeedData = labels.map(label => speedData[label]?.maximum || 0);

  const options = {
    responsive: true,
    scales: {
      x: {
        type: 'category',
        labels: labels.map(label => formatDate(new Date(label), timeInterval)),
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,  
          text: 'Vehicle Speed (km/h)'  
        }
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
      }
    },
  };

  const data = {
    labels: labels.map(label => formatDate(new Date(label), timeInterval)),
    datasets: [
      {
        label: 'Min',
        data: minSpeedData,
        backgroundColor: 'rgb(53, 162, 235)',
      },
      {
        label: 'Average',
        data: avgSpeedData,
        backgroundColor: 'rgb(75, 192, 192)',
      },
      {
        label: 'Max',
        data: maxSpeedData,
        backgroundColor: 'rgb(255, 99, 132)',
      },
    ],
  };


  return (
    <>
      <Bar options={options} data={data} style={{padding: '10px'}}/>
    </>
  );
}

export default ChartSpeed;