import { useState } from 'react';
import { PopupStyle } from './PopupStyle';
import { Marker, Popup } from 'react-map-gl';
import styled from "styled-components";
import { TiWeatherPartlySunny } from "react-icons/ti";

const WsuMarker = styled.div`
  cursor: pointer;
  overflow: hidden;
  border-radius: 20%;
  outline-style: solid;
  outline-width: 2px;
  outline-color: black;
  padding: 5px;
  width: 25px;
  background: ${props => props.status};
  transition: filter 0.2s ease-in-out;
  &:hover {
    filter: brightness(80%)
  }
`;

function WeatherStation(props) {
    const [showPopup, togglePopup] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    const MarkerPopup = () =>
        <PopupStyle>
            <Popup
                latitude={props.message[0].latitude}
                longitude={props.message[0].longitude}
                altitude={2}
                anchor="bottom"
                className="popup"
                sortByDepth={true}
                dynamicPosition={false}
                closeButton={true}
                closeOnClick={false}
                onClose={() => { togglePopup(!showPopup); setIsClicked(false) }}
                offsetLeft={3}
                offsetTop={-12}
            >
                <h1>WSU ID: {props._id}</h1>
                <p> Latitude: {props.message[0].latitude}</p>
                <p> Longitude: {props.message[0].longitude}</p>
            </Popup>
        </PopupStyle >


    const handleMouseEnter = () => {
        if (!isClicked) {
            togglePopup(true);
        }
    };

    const handleMouseLeave = () => {
        if (!isClicked) {
            togglePopup(false);
        }
    };

    const handleClick = (e) => {
        e.stopPropagation(); // To prevent event bubbling if needed
        setIsClicked(!isClicked);
        props.onClick(e);
    };


    return (
        <>
            <Marker latitude={props.message[0].latitude} longitude={props.message[0].longitude} offsetLeft={-15} offsetTop={-10}>
                <WsuMarker 
                    status={props.status}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={handleClick}
                >
                    <TiWeatherPartlySunny size={25} color="black" width="100%" display="flex" justify-content="center" />
                </WsuMarker>
            </Marker>
            {
                showPopup && <MarkerPopup />
            }
        </>
    )
}

export default WeatherStation;