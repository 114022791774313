import { Nav } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

const styles = {
    marginBottom: 10,
};

const CustomNavWeather = ({ active, onSelect, ...props }) => {
    return (
        <Nav {...props} activeKey={active} onSelect={onSelect} style={styles}>
            <Nav.Item eventKey="AverageMet">
                Average
            </Nav.Item>
            <Nav.Item eventKey="BoxPlotMet">
                BoxPlot
            </Nav.Item>
        </Nav>
    );
};

export default CustomNavWeather;