import { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { FaTemperatureLow } from 'react-icons/fa';
import { WiHumidity, WiBarometer } from 'react-icons/wi';
// import { GrSolaris } from 'react-icons/gr';
// import { GiWindsock } from 'react-icons/gi';
import { MdWindPower } from 'react-icons/md';
import { LuSun } from "react-icons/lu";
import { LuCloudRainWind } from "react-icons/lu";
import Switch from "react-switch";
import { AuthContext } from '../Auth/AuthContext';


function WsuTabInfo({temperature, humidity, wind_speed, wind_direction, solar_radiation, atm_pressure, precipitation, active, toggleWsu, wsu_id}) {
  const { isAuthenticated } = useContext(AuthContext);
  const [isOn, setIsOn] = useState(active);

  const windDirection = getCardinalDirection(wind_direction);
  const windSpeed = parseFloat(wind_speed).toFixed(2);

  function getCardinalDirection(degrees) {
    const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
    degrees = (degrees + 360) % 360; // Ensure degrees is positive
    const index = Math.round(degrees / 45) % 8;
    return directions[index];
  }

  const toggleSwitch = () => {
    const invertedState = !isOn;
    setIsOn(invertedState);
    toggleWsu(wsu_id, invertedState);
  };

  useEffect(() => {
    setIsOn(active);
  }, [active]);

  return (
    <WrapperDiv>
      {isAuthenticated && (
        <label style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '5px' }}>
            <b style={{ marginRight: '8px', fontSize: '14px', fontWeight: 'normal' }}>Visibility:</b>
            <Switch
                onChange={toggleSwitch}
                checked={isOn}
                height={20}
                width={40}
                offColor={'#FF0000'}
                borderRadius={10}
            />
        </label>
      )}
      <GridContainer>
        <GridItem>
          <IconWrapper>
            {/* <FaTemperatureHalf size={30} /> */}
            <FaTemperatureLow size={28} />
          </IconWrapper>
          <InfoText>
            <p>Temperature</p>
            {temperature} ºC
          </InfoText>
        </GridItem>
        <GridItem>
          <IconWrapper>
            <WiHumidity size={35} />
          </IconWrapper>
          <InfoText>
            <p>Humidity</p>
            {humidity} %
          </InfoText>
        </GridItem>
        <GridItem>
          <IconWrapper>
            <LuSun size={30} />
          </IconWrapper>
          <InfoText>
            <p>Solar radiation</p>
            {solar_radiation} W/m²
          </InfoText>
        </GridItem>
        <GridItem>
          <IconWrapper>
            <MdWindPower size={30} />
          </IconWrapper>
          <InfoText>
            <p>Wind</p>
          </InfoText>
          <InfoText>
            {windSpeed} m/s
          </InfoText>
          <InfoText>
            {windDirection} 
          </InfoText>
        </GridItem>
        <GridItem>
          <IconWrapper>
            <LuCloudRainWind size={30} />
          </IconWrapper>
          <InfoText>
            <p>Precipitation</p>
            {precipitation} mm
          </InfoText>
        </GridItem>
        {/* <GridItem>
          <IconWrapper>
            <GiWindsock size={30} />
          </IconWrapper>
          <InfoText>
            <p>Wind direction</p>
            {windDirection} 
          </InfoText>
        </GridItem> */}
        <GridItem>
          <IconWrapper>
            <WiBarometer size={40} />
          </IconWrapper>
          <InfoText>
            <p>Atmos. pressure</p>
            {atm_pressure} hPa
          </InfoText>
        </GridItem>
      </GridContainer>
    </WrapperDiv>
  );
}

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  align-items: center;
  justify-items: center;
  text-align: center;
  padding: 20px;
`;

const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center; /* Center-align text */
`;

const IconWrapper = styled.div`
  font-size: 30px;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px; /* Set a fixed width for icons */
  height: 50px; /* Set a fixed height for icons */
`;

const InfoText = styled.div`
  font-size: 16px;
  text-align: center; /* Center-align text */
  @media (max-width: 768px) {
      font-size: 12px;
  }
`;

const WrapperDiv = styled.div`
  text-align: center; 
`;

export default WsuTabInfo;

