import React, { useState, useEffect } from 'react';
import ChartMeteorology from './Charts/ChartLineMeteorology';
import ChartBoxPlotMeteorology from './Charts/ChartBoxPlotMeteorology';
import { Loader } from '../../styles/map_page_styles.js';
import DateTimeRangePicker from './DateTimeRangePicker';
import Dropdown from './Dropdowns/GenericDropdown';
import axios from 'axios';
import styled from "styled-components";

const WeatherChartTab = ({ station_id, activeSection }) => {
  const [meteorologyData, setMeteorologyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [timeInterval, setTimeInterval] = useState(null);
  const [meteorology, setMeteorology] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [window, setWindow] = useState(null);
  const [showChart, setShowChart] = useState(false);
  const [resetDropdown, setResetDropdown] = useState(false);
  const [rangeRequired, setRangeRequired] = useState(true);
  const [windowRequired, setWindowRequired] = useState(true);
  const [meteorologyRequired, setMeteorologyRequired] = useState(true);

  const meteorologyMapping = {
    "": "",
    "temperature": "temperature",
    "humidity": "humidity",
    "solar radiation": "solar_radiation",
    "precipitation": "precipitation",
    "wind speed": "wind_speed",
    "wind direction": "wind_direction",
    "atm. pressure": "pressure"
  };

  const meteorologyOptions = ["", "temperature", "humidity", "solar radiation", "precipitation", "wind speed", "wind direction", "atm. pressure"];

  async function getAvgMeteorologyData(idx) {
    let data = null;
  
    let url = `${process.env.REACT_APP_API_PREFIX}/statistics/weather/average/${meteorology}/${timeInterval}`;
  
    if (timeInterval === 'custom') {
      if (!startDate || !endDate || !window || window === undefined || window === "") {
        return null; 
      }
      url += `?start_date=${encodeURIComponent(startDate)}&end_date=${encodeURIComponent(endDate)}&custom_window=${encodeURIComponent(window)}`;
    }
  
    try {
      const res = await axios({
        method: "get",
        url: url,
        headers: {
          Authorization: process.env.REACT_APP_API_PUBLIC_TOKEN,
        }
      });
  
      data = res.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      return null; 
    }
  
    return data;
  }

  const fetchAvgMeteorologyData = async () => {
    try {
      setLoading(true);
      const data = await getAvgMeteorologyData();
      setMeteorologyData(data);
      setLoading(false);
    }
    catch (error) {
      console.error('Error fetching meteorology data:', error);
      setLoading(false);
    }
  };

  async function getMeteorologyData() {
    let data = null;
    let url = `${process.env.REACT_APP_API_PREFIX}/statistics/weather/${meteorology}/${timeInterval}`;

    if (timeInterval === 'custom') {
      if (!startDate || !endDate || !window || window === undefined || window === "") {
        return null; 
      }
      url += `?start_date=${encodeURIComponent(startDate)}&end_date=${encodeURIComponent(endDate)}&custom_window=${encodeURIComponent(window)}`;
    }
  
    try {
      const res = await axios({
        method: "get",
        url: url,
        headers: {
          Authorization: process.env.REACT_APP_API_PUBLIC_TOKEN,
        }
      });
  
      data = res.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      return null; 
    }
  
    return data;
  }

  const fetchMeteorologyData = async () => {
    try {
      setLoading(true);
      const data = await getMeteorologyData();
      setMeteorologyData(data);
      setLoading(false);
    }
    catch (error) {
      console.error('Error fetching meteorology data:', error);
      setLoading(false);
    }
  };


  const handleMeteorologyChange = (value) => {
    setMeteorology(meteorologyMapping[value]);
    if(value !== '') {
      setMeteorologyRequired(false);
      setResetDropdown(false);
      if (timeInterval && meteorologyMapping[value]) {
        setShowChart(true);
      } else {
        setShowChart(false);
      }
    }
    else{
      setShowChart(false);
      setMeteorologyRequired(true);
    }
  };

  const handleTimeIntervalChange = (value) => {
    if(value !== "custom"){ 
      setWindow("");
    }

    setRangeRequired(false);
    setWindowRequired(true);
    setTimeInterval(value);
    setResetDropdown(false);
    if (value && meteorology) {
        setShowChart(true);
    } else {
        setShowChart(false);
    }
  };

  const handleDateTimeRangeChange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setResetDropdown(false);
    setWindow("");
    setWindowRequired(true);

    if(window !== null && window !== undefined && window !== "") {
      setShowChart(true);
    }
  };

  const handleWindowChange = (value) => {
    setWindow(value);
    setResetDropdown(false);
    if(value !== '') setWindowRequired(false);
    else setWindowRequired(true);
  }

  useEffect(() => {
    // Fetch data only if showChart is true
    if (showChart) {
      if(activeSection === "AverageMet"){
        fetchAvgMeteorologyData();
      }
      else{
        fetchMeteorologyData();
      }  
    }  
  }, [showChart, station_id, timeInterval, meteorology, activeSection, window, startDate, endDate]);


  useEffect(() => {
    setShowChart(false);
    setResetDropdown(true);
    setMeteorology("");
    setTimeInterval("");
    setWindow("");
    setWindowRequired(true);
    setMeteorologyRequired(true);
    setRangeRequired(true);
  },[activeSection]);

  let ChartComponent;
  let chartProps = {}; 

  if (activeSection === "AverageMet") {
    ChartComponent = ChartMeteorology;
    const containerId = "meteorologyChart";
    chartProps = { containerId, meteorologyData, meteorology, timeInterval };
  }
  else if (activeSection === "BoxPlotMet") {
    ChartComponent = ChartBoxPlotMeteorology;
    const containerId = "meteorologyBoxPlotChart";
    chartProps = { containerId, meteorologyData, meteorology, timeInterval };
  }

  return (
    <div>
      <DropdownContainer>
      <DateTimeRangePicker 
          handleTimeIntervalChange={handleTimeIntervalChange} 
          handleDateTimeRangeChange={handleDateTimeRangeChange} 
          handleWindowChange={handleWindowChange} 
          activeSection={activeSection} 
          selectedWindow={window}
          resetDropdown={resetDropdown}
          rangeRequired={rangeRequired}
          windowRequired={windowRequired}
        />

        <Dropdown 
            label={"Meteorology"} 
            options={meteorologyOptions} 
            onSelectChange={handleMeteorologyChange} 
            selected={meteorology} 
            resetDropdown={resetDropdown}
            isRequired={meteorologyRequired} 
          />
      </DropdownContainer>

      {loading ? (
        <Loader />
      ) : (
        showChart && ChartComponent ? <ChartComponent {...chartProps} /> : null
      )}
    </div>
  );
};

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: row; 
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  gap: 15px;
  // margin-top: 10px;
`;


export default WeatherChartTab;
