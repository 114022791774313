import React, { useState } from 'react';
import { Marker, Popup } from 'react-map-gl';
import styled from 'styled-components';
import ObjectIcon from './ObjectIcon'; 

const CarMarker = styled.div`
  cursor: pointer;
  overflow: hidden;
  background: lightblue;
  border-radius: 50%;
  outline-style: solid;
  outline-width: 2px;
  outline-color: black;
  transition: filter 0.2s ease-in-out;
  &:hover {
    filter: brightness(80%)
  }
`;

function Cpm(props) {
    const [showPopup, togglePopup] = useState(false)
    const personTypes = ["unknownP", "pedestrian", "personInWheelchair", "cyclist", "personWithStroller", "personOnSkates", "personGroup"]
    const vehicleTypes = ["unknown", "moped", "motorcycle", "passengerCar", "bus", "lightTruck", "heavyTruck", "trailer", "specialVehicles", "tram", "emergencyVehicle", "agricultural"]
    
    const className = props.data[6] !== null ? props.data[6].toString() : "";
    const classType = props.data[7];

    let subClassName = "unknown";

    if (classType !== null) {
        if (className === "vehicle") {
            subClassName = vehicleTypes[classType] || "unknown";
        } 
        else if (className === "person") {
            subClassName = personTypes[classType] || "unknown";
        }
    }

    const MarkerPopup = () =>
        <Popup
            latitude={props.data[0]}
            longitude={props.data[1]}
            anchor="bottom"
            className="popup"
            closeButton={true}
            dynamicPosition={false}
            sortByDepth={true}
            closeOnClick={false}
            onClose={() => {
                togglePopup(!showPopup)
                props.clearSelectedCPM(props.data[5])
            }}
            style={{zIndex:-1}}
        >
            <br></br><h5>ID: {props.data[3]}</h5><br></br>
            <p><b>Velocity:</b> {props.data[2]} km/h</p>
            <p><b>Class:</b> {subClassName} </p>
            {/* <p><b>RSU ID:</b> {props.data[4]} </p> */}
        </Popup>

    return (
        <>
            <Marker
                longitude={props.data[1]}
                latitude={props.data[0]}
                offsetLeft={-12.5}
                offsetTop={-10}
            >

                <CarMarker onClick={e => {
                    e.preventDefault()
                    togglePopup(true)
                    props.handleCPMClick({rsu_id: props.data[4], cpm_id: props.data[5]});
                }}>

                    <ObjectIcon  subClassName={subClassName}/>
                    
                </CarMarker>

            </Marker>
            {
                showPopup &&
                
                <MarkerPopup />
                
            }
        </>
    )
}

export default Cpm