import { Nav } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

const styles = {
    marginBottom: 10,
};

const CustomNavStatistics = ({ active, onSelect, ...props }) => {
    return (
        <Nav {...props} activeKey={active} onSelect={onSelect} style={styles}>
            <Nav.Item eventKey="NumberOfVehicles">
                Number of Vehicles
            </Nav.Item>
            <Nav.Item eventKey="Speed">
                Vehicle Speed
            </Nav.Item>
            <Nav.Item eventKey="AvgSpeed">
                Vehicle Average Speed
            </Nav.Item>
        </Nav>
    );
};

export default CustomNavStatistics;