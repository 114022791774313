export const denm_images = {
    "accident": "http://ccam.av.it.pt/denm_images/accident.svg",
    "lane_loss": "http://ccam.av.it.pt/denm_images/lane_loss.svg",
    "sign_violation": "http://ccam.av.it.pt/denm_images/sign_violation.svg",
    "animals_on_the_road": "http://ccam.av.it.pt/denm_images/animals_on_the_road.svg",
    "low_visibility": "http://ccam.av.it.pt/denm_images/low_visibility.svg",
    "slippery_road": "http://ccam.av.it.pt/denm_images/slippery_road.svg",
    "bad_weather": "http://ccam.av.it.pt/denm_images/bad_weather.svg",
    "people_on_the_road": "http://ccam.av.it.pt/denm_images/people_on_the_road.svg",
    "slow_vehicle": "http://ccam.av.it.pt/denm_images/slow_vehicle.svg",
    "collision_risk": "http://ccam.av.it.pt/denm_images/collision_risk.svg",
    "post_accident": "http://ccam.av.it.pt/denm_images/post_accident.svg",
    "stopped_vehicle": "http://ccam.av.it.pt/denm_images/stopped_vehicle.svg",
    "dangerous_curve": "http://ccam.av.it.pt/denm_images/dangerous_curve.svg",
    "precipitation": "http://ccam.av.it.pt/denm_images/precipitation.svg",
    "traffic": "http://ccam.av.it.pt/denm_images/traffic.svg",
    "danger": "http://ccam.av.it.pt/denm_images/danger.svg",
    "road_obstacle": "http://ccam.av.it.pt/denm_images/road_obstacle.svg",
    "vehicle_breakdown": "http://ccam.av.it.pt/denm_images/vehicle_breakdown.svg",
    "emergency_vehicles": "http://ccam.av.it.pt/denm_images/emergency_vehicles.svg",
    "road_surface_risk": "http://ccam.av.it.pt/denm_images/road_surface_risk.svg",
    "wrong_way": "http://ccam.av.it.pt/denm_images/wrong_way.svg",
    "human_problem": "http://ccam.av.it.pt/denm_images/human_problem.svg",
    "road_works": "http://ccam.av.it.pt/denm_images/road_works.svg"
}

export default denm_images
