import { useState, useEffect } from 'react';
import { PopupStyle } from './PopupStyle';
import { Marker, Popup } from 'react-map-gl';
import styled from "styled-components";
import radio_tower from "../../../static/radio_tower.png";

const RsuMarker = styled.div`
  cursor: pointer;
  overflow: hidden;
  border-radius: 20%;
  outline-style: solid;
  outline-width: 2px;
  outline-color: black;
  padding: 5px;
  width: 25px;
  background: ${props => props.status};
  transition: filter 0.2s ease-in-out;
  &:hover {
    filter: brightness(80%)
  }
`;

function RsuStation(props) {
    const [showPopup, togglePopup] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    const MarkerPopup = () =>
        <PopupStyle>
            <Popup
                latitude={props.message[0].position[0]}
                longitude={props.message[0].position[1]}
                altitude={2}
                anchor="bottom"
                className="popup"
                sortByDepth={true}
                dynamicPosition={false}
                closeButton={true}
                closeOnClick={false}
                onClose={() => { togglePopup(!showPopup); setIsClicked(false) }}
                offsetLeft={3}
                offsetTop={-12}
                style={{zIndex:1}}
            >
                <h1>RSU ID: {props._id}</h1>
                <p> Latitude: {props.message[0].position[0]}</p>
                <p> Longitude: {props.message[0].position[1]}</p>
            </Popup>
        </PopupStyle >

    useEffect(() => {
        if(props.closePopup){ 
            togglePopup(false);
            setIsClicked(false);
        }
    }, [props.closePopup]);

    const handleMouseEnter = () => {
        if (!isClicked) {
            togglePopup(true);
        }
    };

    const handleMouseLeave = () => {
        if (!isClicked) {
            togglePopup(false);
        }
    };

    const handleClick = (e) => {
        e.stopPropagation(); // To prevent event bubbling if needed
        setIsClicked(!isClicked);
        props.onClick(e);
    };

    return (
        <>
            <Marker latitude={props.message[0].position[0]} longitude={props.message[0].position[1]} offsetLeft={-15} offsetTop={-10}>
                <RsuMarker
                    status={props.status}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={handleClick}
                >
                   <img src={radio_tower} alt="" width="100%" style={{ display: "flex", justifyContent: "center" }} />
                </RsuMarker>
            </Marker>
            {
                showPopup && <MarkerPopup />
            }
        </>
    )
}

export default RsuStation;