import React from 'react';
import '../styles/generic_menu.css';
import '../styles/constants.css';
import v2x_background from '../static/background2.jpg';
import { useHistory } from "react-router-dom";
import { useContext } from 'react';
import { AuthContext } from '../components/Auth/AuthContext';

const Landing_page = () => {
  const { isAuthenticated } = useContext(AuthContext);

  const history = useHistory();
  
  const routeChangeMap = () =>{ 
    let path = `/map`; 
    history.push(path);
  }

  const routeChangeLogin = () =>{ 
    let path = `/login`; 
    history.push(path);
  }
  
  return (
  <div className="scrollable-background flex-aligned" style={{backgroundImage: `url(${v2x_background})`}}>
    <div className="white-box-content small-info" >
      <h1 className="title1-text mb-15">Welcome to CCAM</h1>
      <button className="blue-button" onClick={routeChangeMap}>View Map</button>
      {!isAuthenticated ? <button className="blue-button ml-5" onClick={routeChangeLogin}>Login</button> : null}
    </div>
  </div>
  );
};

export default Landing_page;