import React, { useState, useEffect } from 'react';
import ChartSpeed from './Charts/ChartBarsSpeed';
import ChartAvgSpeed from './Charts/ChartBoxPlotSpeed';
import ChartNumberOfVehicles from './Charts/ChartNumberOfVehicles';
import { Loader } from '../../styles/map_page_styles.js';
import DropdownObjClass from './Dropdowns/DropdownObjClass';
import DateTimeRangePicker from './DateTimeRangePicker';
import Dropdown from './Dropdowns/GenericDropdown';
import axios from 'axios';
import styled from "styled-components";

const TrafficChartTab = ({ station_id, activeSection }) => {
  const [speedData, setSpeedData] = useState([]);
  const [inOutflow, setInOutflow] = useState([]);
  const [loading, setLoading] = useState(false);
  const [timeInterval, setTimeInterval] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [window, setWindow] = useState(null);
  const [direction, setDirection] = useState(null);
  const [objClass, setObjClass] = useState(null);
  const [showChart, setShowChart] = useState(false);
  const [resetDropdown, setResetDropdown] = useState(false);
  const [rangeRequired, setRangeRequired] = useState(true);
  const [windowRequired, setWindowRequired] = useState(true);
  const [directionRequired, setDirectionRequired] = useState(true);

  const directionOptions = ["", "inflow", "outflow"];

  async function getSpeedData(idx) {
    let data = null;
  
    let url = `${process.env.REACT_APP_API_PREFIX}/statistics/speed/${idx}/${timeInterval}/${direction}`;
  
    if (objClass !== "" && objClass !== null && objClass !== undefined) { 
      url += `/${objClass}`;
    }
  
    if (timeInterval === 'custom') {
      if (!startDate || !endDate || !window || window === undefined || window === "") {
        return null; 
      }
      url += `?start_date=${encodeURIComponent(startDate)}&end_date=${encodeURIComponent(endDate)}&custom_window=${encodeURIComponent(window)}`;
    }
  
    try {
      const res = await axios({
        method: "get",
        url: url,
        headers: {
          Authorization: process.env.REACT_APP_API_PUBLIC_TOKEN,
        }
      });
  
      data = res.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      return null; 
    }
  
    return data;
  }

  const fetchSpeedData = async (key) => {
    try {
      // console.log('Fetching speed data from radar: ', key);
      setLoading(true);
      const data = await getSpeedData(key);
      setSpeedData(data);
      setLoading(false);
    }
    catch (error) {
      console.error('Error fetching statistics data:', error);
      setLoading(false);
    }
  };

  async function getNumberOfVehiclesData(idx) {
    let data = null;
  
    let url = `${process.env.REACT_APP_API_PREFIX}/statistics/vehicles/${idx}/${timeInterval}`;
  
    if (objClass !== "" && objClass !== null && objClass !== undefined) { 
      url += `/${objClass}`;
    }
  
    if (timeInterval === 'custom') {
      if (!startDate || !endDate || !window || window === undefined || window === "") {
        return null; 
      }
      url += `?start_date=${encodeURIComponent(startDate)}&end_date=${encodeURIComponent(endDate)}&custom_window=${encodeURIComponent(window)}`;
    }
  
    try {
      const res = await axios({
        method: "get",
        url: url,
        headers: {
          Authorization: process.env.REACT_APP_API_PUBLIC_TOKEN,
        }
      });
  
      data = res.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      return null; 
    }
  
    return data;
  }
  

  const fetchNumberOfVehiclesData = async (key) => {
    try {
      // console.log('Fetching inflow and outflow data from radar: ', key);
      setLoading(true);
      const data = await getNumberOfVehiclesData(key);
      setInOutflow(data);
      setLoading(false);
    }
    catch (error) {
      console.error('Error fetching statistics data:', error);
      setLoading(false);
    }
  };

  const handleTimeIntervalChange = (value) => {
    if(value !== "custom"){ 
      setWindow("");
    }

    setRangeRequired(false);
    setWindowRequired(true);
    setTimeInterval(value);
    setResetDropdown(false);
    if (activeSection !== "NumberOfVehicles"){
      if (value && direction) {
        setShowChart(true);
      } else {
        setShowChart(false);
      }
    }
    else{
      setShowChart(true);
    }
  };

  const handleDateTimeRangeChange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setResetDropdown(false);
    setWindow("");
    setWindowRequired(true);

    if(window !== null && window !== undefined && window !== "") {
      setShowChart(true);
    }
  };

  const handleWindowChange = (value) => {
    setWindow(value);
    setResetDropdown(false);
    if(value !== '') setWindowRequired(false);
    else setWindowRequired(true);
  }

  const handleDirectionChange = (value) => {
    setDirection(value);
    if(value !== '') {
      setDirectionRequired(false);
      setResetDropdown(false);
      if (timeInterval && value) {
        setShowChart(true);
      } else {
        setShowChart(false);
      }
    }
    else{
      setShowChart(false);
      setDirectionRequired(true);
    }
  };

  const handleObjClassChange = (value) => {
    setObjClass(value);
    setResetDropdown(false);
  };

  useEffect(() => {
    // Fetch data only if showChart is true
    if (showChart) {
      if(activeSection === "NumberOfVehicles"){
        fetchNumberOfVehiclesData(station_id);
      }
      else{
        fetchSpeedData(station_id);
      }
    }  
  }, [showChart, station_id, timeInterval, direction, objClass, window, startDate, endDate]);

  useEffect(() => {
    setShowChart(false);
    setResetDropdown(true);
    setDirection("");
    setTimeInterval("");
    setWindow("");
    setObjClass("");
    setWindowRequired(true);
    setDirectionRequired(true);
    setRangeRequired(true);
  },[activeSection]);

  let ChartComponent;
  let chartProps = {}; 

  if (activeSection === "NumberOfVehicles") {
    ChartComponent = ChartNumberOfVehicles;
    chartProps = { inOutflow, timeInterval };
  }
  else if (activeSection === "Speed") {
    ChartComponent = ChartSpeed;
    chartProps = { speedData, timeInterval };
  }
  else if (activeSection === "AvgSpeed") {
    ChartComponent = ChartAvgSpeed;
    const data = "graph";
    chartProps = { data, speedData, timeInterval };
  }


  return (
    <div>
      <DropdownContainer layout={timeInterval === "custom" && activeSection !== "NumberOfVehicles" ? 'column' : 'row'}>
        <DateTimeRangePicker 
          handleTimeIntervalChange={handleTimeIntervalChange} 
          handleDateTimeRangeChange={handleDateTimeRangeChange} 
          handleWindowChange={handleWindowChange} 
          activeSection={activeSection} 
          selectedWindow={window}
          resetDropdown={resetDropdown}
          rangeRequired={rangeRequired}
          windowRequired={windowRequired}
        />

        <DirectionAndClassContainer>
          {activeSection !== "NumberOfVehicles" && 
            <DropdownDirection>
              <Dropdown 
                label={"Direction"} 
                options={directionOptions} 
                onSelectChange={handleDirectionChange} 
                selected={direction} 
                resetDropdown={resetDropdown}
                isRequired={directionRequired} 
              />
            </DropdownDirection>
          }
          <DropdownObjClass onSelectChange={handleObjClassChange} resetDropdown={resetDropdown} />
        </DirectionAndClassContainer>
      </DropdownContainer>

      {loading ? (
        <Loader />
      ) : (
        showChart && ChartComponent ? <ChartComponent {...chartProps} /> : null
      )}
    </div>
  );
};

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.layout === 'column' ? 'column' : 'row'};
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  gap: 15px; 
`;

const DropdownDirection = styled.div`
  margin-right: 10px; 
`;

const DirectionAndClassContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 15px; 
`;

export default TrafficChartTab;
