import { useState, useEffect, useContext } from 'react';
import styled from "styled-components";
import axios from 'axios';
import { FaTimes, FaEdit } from 'react-icons/fa';
import { AuthContext } from '../Auth/AuthContext';


function DisplayAlerts({ onAlertClick, onCancelAlert, onUpdateAlert }) {
  const [activeArray, setActiveArray] = useState([]);
  const [expiredArray, setExpiredArray] = useState([]);
  const [hoveredAlert, setHoveredAlert] = useState(null);
  const [refreshList, setRefreshList] = useState(false);
  const [cancelInProgress, setCancelInProgress] = useState({});
  const { isAuthenticated } = useContext(AuthContext);


  async function getAlerts() {
    const res = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_PREFIX}/alert/`,
      headers: {
        Authorization: process.env.REACT_APP_API_PUBLIC_TOKEN,
      }
    });
    const currentDate = new Date();

    const activeAlerts = res.data.filter(alert => new Date(alert.alert_deadline) > currentDate && alert.termination == null);
    setActiveArray(activeAlerts);

    const expiredAlerts = res.data.filter(alert => new Date(alert.alert_deadline) <= currentDate || alert.termination != null);
    setExpiredArray(expiredAlerts);
  }

  useEffect(() => {
    async function fetchAlerts() {
      await getAlerts();
    }
    fetchAlerts();
  }, [refreshList]);

  function parseDate(date_obj) {
    if (!date_obj)
      return 'No date provided';
    const date = new Intl.DateTimeFormat('pt-PT', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    }).format(date_obj);

    return date;
  }

  function handleAlertClick(alertId) {
    onAlertClick(alertId, true);
  }

  async function handleCancelAlert(alert) {
    if (!cancelInProgress[alert._id]) {
      setCancelInProgress(prev => ({ ...prev, [alert._id]: true }));
      await onCancelAlert(alert).then(() => {
        setRefreshList(prevValue => !prevValue);
      }).finally(() => {
        setCancelInProgress(prev => ({ ...prev, [alert._id]: false }));
      });
    }
  }

  function handleUpdateAlert(alert) {
    // console.log("Update Alert: ", alert._id);
    onUpdateAlert(alert);
  }

  return (
    <WrapperDiv>
      <SectionTitleActive>Active Alerts</SectionTitleActive>
      {activeArray.map((alert, index) => (
        <ItemsDiv key={alert._id} active even={index % 2 === 0} onMouseEnter={() => setHoveredAlert(alert._id)} onMouseLeave={() => setHoveredAlert(null)}>
          <AlertInfo onClick={() => handleAlertClick(alert._id)}>
            <div>Originating Station ID: {alert.station_id}</div>
            <div>Latitude: {alert.latitude}</div>
            <div>Longitude: {alert.longitude}</div>
            <div>Sub Cause Name: {alert.sub_cause_name}</div>
            <div>Deadline Date: {parseDate(alert.alert_deadline)}</div>
          </AlertInfo>

          {isAuthenticated && hoveredAlert === alert._id && (
            <ButtonWrapper>
              { alert.station_id === 1 ? // Only DENMs generated by CCAM can be updated
                  <UpdateButton onClick={() => handleUpdateAlert(alert)}>
                    <UpdateIcon>
                      <FaEdit />
                    </UpdateIcon>
                  </UpdateButton>
                : null
              }

              <CancelButton onClick={() => handleCancelAlert(alert)} disabled={cancelInProgress[alert._id]}>
                <CancelIcon>
                  <FaTimes />
                </CancelIcon>
              </CancelButton>
            </ButtonWrapper>
          )}
        </ItemsDiv>
      ))}

      <SectionTitleExpired>Expired Alerts</SectionTitleExpired>
      {expiredArray.map((alert, index) => (
        <ItemsDiv key={alert._id} active={false} even={index % 2 === 0}>
          <AlertInfo>
            <div>Originating Station ID: {alert.station_id}</div>
            <div>Latitude: {alert.latitude}</div>
            <div>Longitude: {alert.longitude}</div>
            <div>Sub Cause Name: {alert.sub_cause_name}</div>
            <div>Deadline Date: {parseDate(alert.alert_deadline)}</div>
            <div>{alert.termination_time && `Termination Date: ${parseDate(alert.termination_time)}`}</div>
            <div>{alert.termination === 0 && "[CANCELED]"}</div>
            <div>{alert.termination === 1 && "[NEGATED]"}</div>
          </AlertInfo>
        </ItemsDiv>
      ))}

    </WrapperDiv>
  );
}

const ItemsDiv = styled.div`
  padding: 0.25rem 1rem;
  display: flex;
  align-items: center;
  background-color: ${props => props.active ? (props.even ? '#f2fff2' : '#d1ecc1') : (props.even ? '#FFEBEB' : '#FFDADA')};
  &:hover {
    background-color: ${props => props.active ? '#C8E6C9' : '#FFCDD2'};
  }
  cursor: pointer;
`;

const WrapperDiv = styled.div`
    position: relative;
    display: row;
    overflow-y: auto;
    max-height: 400px;
    align-items: center;
    -ms-overflow-style: none;
    scrollbar-width: none;
`;

const SectionTitleActive = styled.h2`
    font-size: 1.2rem;
    color: green;
`;

const SectionTitleExpired = styled.h2`
    font-size: 1.2rem;
    color: red;
`;
const AlertInfo = styled.div`
  flex: 1;
  margin-right: 1rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem; 
`;

const CancelButton = styled.button`
  background-color: lightcoral;
  border: none;
  padding: 0.2rem 0.4rem;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 1.8rem; /* Set the height to adjust the button size */
  width: 1.8rem; /* Set the width to make the button a perfect circle */
`;

const UpdateButton = styled.button`
  background-color: lightblue;
  border: none;
  padding: 0.2rem 0.4rem;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 1.8rem; /* Set the height to adjust the button size */
  width: 1.8rem; /* Set the width to make the button a perfect circle */
`;

const CancelIcon = styled.span`
  font-size: 1.2rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; /* Set the height to occupy the entire button */
`;

const UpdateIcon = styled.span`
  font-size: 1.2rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; 
`;

export default DisplayAlerts;


