import React, { useState, useEffect } from 'react';
import { Marker } from 'react-map-gl';
import styled from 'styled-components';
import ObjectIcon from './ObjectIcon'; 

const CarMarker = styled.div`
  cursor: pointer;
  overflow: hidden;
  background: thistle;
  border-radius: 50%;
  outline-style: solid;
  outline-width: 2px;
  outline-color: black;
  transition: transform 2s ease-in-out;
  &:hover {
    filter: brightness(80%);
  }
`;

function Tum(props) {
  const [currentCoordinates, setCurrentCoordinates] = useState({
    longitude: props.locations[0].longitude,
    latitude: props.locations[0].latitude
  });
  const [currentLocationIndex, setCurrentLocationIndex] = useState(0);

  useEffect(() => {
    const intervalDuration = props.locations.length <= 2 ? 1000 : 750;

    const animateMarker = () => {
      let index = 0;
      const interval = setInterval(() => {
        if (index < props.locations.length) {
          setCurrentCoordinates({
            longitude: props.locations[index].longitude,
            latitude: props.locations[index].latitude
          });
          setCurrentLocationIndex(index);
          index++;
        } else {
          clearInterval(interval);
          if (!props.isPopupOpen) {
            // Remove marker after animation if popup is not open
            props.removeMarker(props.id);
          }
        }
      }, intervalDuration);
    };

    animateMarker();
  }, [props.locations, props.isPopupOpen]);

  return (
    <Marker
      longitude={currentCoordinates.longitude}
      latitude={currentCoordinates.latitude}
      offsetLeft={-12.5}
      offsetTop={-10}
    >
      <CarMarker
        onClick={(e) => {
          e.preventDefault();
          props.onMarkerClick({
            stationId: props.stationId,
            latitude: currentCoordinates.latitude,
            longitude: currentCoordinates.longitude,
            vehicleType: props.vehicleType,
            charge: props.charge
          });
        }}
      >
        <ObjectIcon />
      </CarMarker>
    </Marker>
  );
}

export default Tum;


