import React, { useState } from 'react';
import { Marker, Popup } from 'react-map-gl';
import { PopupStyle } from './PopupStyle'
import styled from 'styled-components';
import ObjectIcon from './ObjectIcon'; 

const CarMarker = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  overflow: hidden;
  background: yellow;
  border-radius: 50%;
  outline-style: solid;
  outline-width: 2px;
  outline-color: black;
  transition: filter 0.2s ease-in-out;
  &:hover {
    filter: brightness(80%);
  }
`;

// StationType ::= INTEGER {unknown(0), pedestrian(1), cyclist(2), 
// moped(3), motorcycle(4), passengerCar(5), bus(6), lightTruck(7), 
// heavyTruck(8), trailer(9), specialVehicles(10), tram(11), 
// roadSideUnit(15)}

function Cam(props) {
    const [showPopup, togglePopup] = useState(false)
    const { id, ...markerProps } = props
    const [prevCoordinates, setPrevCoordinates] = useState([props.longitude, props.latitude]);
    const stationTypes = {
        1: "pedestrian",
        2: "cyclist",
        3: "moped",
        4: "motorcycle",
        5: "passengerCar",
        6: "bus",
        7: "lightTruck",
        8: "heavyTruck",
        9: "trailer",
        10: "specialVehicles",
        11: "tram",
        15: "roadSideUnit",
    };
    
    let stationTypeName = stationTypes[props.station_type] || "unknown";
    const rsuStationIdsArray = String(props.rsu_stationIds).split(',');
    const rsuStationIdsString = rsuStationIdsArray.join(', ');

    const MarkerPopup = () =>
    <PopupStyle>
        <Popup
            latitude={props.latitude}
            longitude={props.longitude}
            anchor="bottom"
            className="popup"
            closeButton={true}
            dynamicPosition={false}
            sortByDepth={true}
            closeOnClick={false}
            onClose={() => togglePopup(!showPopup)}
        >
            <br></br><h5><b>ID:</b> {props.stationId}</h5><br></br>
            <p><b>Latitude:</b> {props.latitude}</p>
            <p><b>Longitude:</b> {props.longitude}</p>
            <p><b>Station Type:</b> {stationTypeName}</p>
            <p><b>RSU Station ID's:</b> {rsuStationIdsString}</p>

        </Popup>
    </PopupStyle>

    // Calculate the transform property for sliding animation
    const transform = `translate(${props.longitude - prevCoordinates[0]}px, ${
        props.latitude - prevCoordinates[1]
    }px)`;

    return (
        <>
            <Marker {...markerProps} offsetLeft={-12.5} offsetTop={-10}>
                <CarMarker
                    onClick={(e) => {
                        e.preventDefault();
                        togglePopup(true);
                    }}
                    style={{ transform }} // Apply the transform property for sliding effect
                >
                    <ObjectIcon subClassName={stationTypeName} />
                </CarMarker>
            </Marker>
            {
                showPopup && <MarkerPopup />
            }
        </>
    )
}

export default Cam
