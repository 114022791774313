import { React, useState, useEffect } from 'react';
import { ProSidebar, SidebarContent, Menu, MenuItem } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import 'react-pro-sidebar/dist/css/styles.css';
import { RiDashboardLine, RiBroadcastLine, RiHome3Line} from 'react-icons/ri';
import { MdOutlineToken } from "react-icons/md";
import { AiOutlineApi } from "react-icons/ai";
import { FaQuestion } from "react-icons/fa";
import { MdOndemandVideo } from "react-icons/md";
import { GrLogin, GrLogout } from "react-icons/gr";
import { useSelector, useDispatch, } from 'react-redux'
import { set, unset } from '../../features/CollapsedSlice'
import { withRouter } from "react-router-dom";
import { AuthContext } from '../Auth/AuthContext';
import { useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import styled from "styled-components";
import 'react-toastify/dist/ReactToastify.css';

const Sidebar = ({history, toggled, handleToggleSidebar}) => {
    const collapsed = useSelector(state => state.collapsed.value)
    const { isAuthenticated, isUser, logout } = useContext(AuthContext);
    const dispatch = useDispatch()

    function notify(message, type) {
        if (type === "success")
            toast.success(message, {
            position: toast.POSITION.BOTTOM_CENTER
        });
        else if (type === "error")
            toast.error(message, {
            position: toast.POSITION.BOTTOM_CENTER
        });
        else 
            toast.info(message, {
            position: toast.POSITION.BOTTOM_CENTER
        });
    }
   
    const handleLogout = () => {
        const response  = logout();

        if(response){ 
            notify("Logged out successfully");
        } 
        else {
            notify("An error occurred while trying to logout", "error");
        }
    };

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleMouseLeave = () => dispatch(set());
    const handleMouseEnter = () => dispatch(unset());

    return (
        <ProSidebar onMouseLeave={screenWidth > 768 ? handleMouseLeave : null} onMouseEnter={screenWidth > 768 ? handleMouseEnter : null} breakPoint="md" collapsed={collapsed} toggled={toggled} onToggle={handleToggleSidebar}>
            <SidebarContent>
                <Menu iconShape="circle">
                    <MenuItem icon={<RiHome3Line />}>
                        Home
                        <Link to="/" />
                    </MenuItem>
                </Menu>
                <Menu iconShape="circle">
                    <MenuItem icon={<RiBroadcastLine />}>
                        Map
                        <Link to="/map" />
                    </MenuItem>
                </Menu>
                <Menu iconShape="circle">
                    <MenuItem icon={<AiOutlineApi />} >
                        API
                        <Link to="/doc" />
                    </MenuItem>
                </Menu>
                {(isAuthenticated || isUser) ? (
                <MenuHiddenMobile iconShape="circle">
                    <MenuItem icon={<MdOutlineToken />} >
                        API Token
                        <Link to="/token" />
                    </MenuItem>
                </MenuHiddenMobile>
                ) : null}
                <MenuHiddenMobile iconShape="circle">
                    <MenuItem icon={<RiDashboardLine />}>
                        Grafana
                        <Link to="/grafana" />
                    </MenuItem>
                </MenuHiddenMobile>
                <Menu iconShape="circle">
                    <MenuItem icon={<MdOndemandVideo />}>
                        Videos
                        <Link to="/video" />
                    </MenuItem>
                </Menu>
                <Menu iconShape="circle">
                    <MenuItem icon={<FaQuestion />}>
                        About
                        <Link to="/about" />
                    </MenuItem>
                </Menu>
            </SidebarContent>
            {(isAuthenticated || isUser) ? (
                <StyledSidebarFooter onClick={handleLogout}>
                    <div>
                        <GrLogout size={25} />
                    </div>
                    </StyledSidebarFooter>
            ) : (
                <StyledSidebarFooter onClick={() => history.push('/login')}>
                    <div>
                        <GrLogin size={25} />
                    </div>
                </StyledSidebarFooter>
            )}
            <ToastContainer />
        </ProSidebar>
    );
}

const StyledSidebarFooter = styled.div`
  padding: 1rem;
  background-color: white;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  color: black;
`;

const MenuHiddenMobile = styled(Menu)`
    @media (max-width: 768px) {
        display: none;
    }
`;

export default withRouter(Sidebar)
