import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import axios from 'axios';

function TumMessageList({ tollChargerId, tollPointId }) {
    const [messages, setMessages] = useState([]);
    const [expandedIndexes, setExpandedIndexes] = useState([]);

    useEffect(() => {
      async function getTumMessages() {
        try {
          const response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_PREFIX}/tum`,
            headers: {
              Authorization: process.env.REACT_APP_API_PUBLIC_TOKEN,
            },
            data: {
              tollChargerId,
              tollPointId
            }
          });

          const responseData = response.data;

          const manipulatedData = responseData.map(item => ({
            tempID: item.tempID,
            tollChargerId: item.tollChargerId,
            tollPointId: item.tollPointId,
            tumSequenceNum: item.tumSequenceNum,
            tamSequenceNum: item.tamSequenceNum,
            vehicleId: item.vehicleId,
            vehicleType: item.vehicleType,
            charge: item.charge,
            updatedAt: item.updatedAt,
            // locations: item.locations,
            // ack: item.ack,
          }));

          setMessages(manipulatedData);

          if (Array.isArray(manipulatedData) && manipulatedData.length > 0) {
            const initialExpandedState = Array(manipulatedData.length).fill(
              false
            );
            setExpandedIndexes(initialExpandedState);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }

      getTumMessages();
    }, [tollChargerId, tollPointId]);

    const toggleExpand = index => {
      const updatedExpandedIndexes = [...expandedIndexes];
      updatedExpandedIndexes[index] = !updatedExpandedIndexes[index];
      setExpandedIndexes(updatedExpandedIndexes);
    };

    function parseDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString('pt-PT', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
      });
    }

    return (
      <div>
        {messages?.length > 0 ? (
          messages.map((message, index) => (
            <MessageContainer
              key={index}
              highlighted={expandedIndexes[index]}
              onClick={() => toggleExpand(index)}
            >
              <div>
                <b>Message #{index + 1}</b>
                <br />
                <b>Date: </b>{parseDate(message.updatedAt)}
                <br />
                <b>Temporary ID: </b>{message.tempID}
                {/* <br /> */}
              </div>
              {expandedIndexes[index] && (
                <div>
                  <b>Toll Charger ID: </b>{message.tollChargerId}
                  <br />
                  <b>Toll Point ID: </b>{message.tollPointId}
                  <br />
                  <b>TUM Sequence Number: </b>{message.tumSequenceNum}
                  <br />
                  <b>TAM Sequence Number: </b>{message.tamSequenceNum}
                  <br />
                  <b>Vehicle ID: </b>{message.vehicleId}
                  <br />
                  <b>Vehicle Type: </b>{message.vehicleType}
                  <br />
                  <b>Charge: </b>{message.charge}
                  <br />
                  {/* <b>Locations: </b>{JSON.stringify(message.locations)}
                  <br />
                  <b>Acknowledged: </b>{message.ack ? 'Yes' : 'No'} */}
                </div>
              )}
            </MessageContainer>
          ))
        ) : (
          <div>No messages available.</div>
        )}
      </div>
    );
}

const MessageContainer = styled.div`
  padding: 10px;
  border: 1px solid #ccc;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  animation: ${props => (props.highlighted ? pulseAnimation : 'none')};
  background-color: ${props => (props.highlighted ? '#f0f0f0' : 'initial')};
  text-align: left;

  &:hover {
    animation: pulseAnimation 1.5s infinite alternate;
    background-color: #f0f0f0;
  }
`;

// Define the pulse animation using keyframes
const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
`;

export default TumMessageList;
