import styled from "styled-components";
import Switch from "react-switch";
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../Auth/AuthContext';

function RsuTabInfo(props) {
    const { isAuthenticated } = useContext(AuthContext);
    const [isOn, setIsOn] = useState(props.active);

    function parseDate(obj) {
        const date = new Intl.DateTimeFormat('pt-PT', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        }).format(obj)

        return date
    }

    const active_denm_list = props.message[0].activeDENM
    const num_denms = Object.keys(active_denm_list).length
    var events_string = "None"

    const denm_list = typeof active_denm_list === 'string' ? JSON.parse(active_denm_list) : active_denm_list;

    // Transform each element of the array into a string with the desired format
    const denm_info_strings = denm_list.map(denm => {
        const originatingStationID = denm.originatingStationID;
        const sequenceNumber = denm.sequenceNumber;
        const referenceTimeOriginal = denm.referenceTime;
        const referenceTime = new Date(referenceTimeOriginal+ 1072915200000 - 5000 );
        const dateString = referenceTime.toLocaleDateString('pt-PT');
        const timeString = referenceTime.toLocaleTimeString('en-US', {hour12: false});
        const formattedTime = `${dateString} ${timeString}`;

        return `${originatingStationID} , ${sequenceNumber} , ${formattedTime}`;
    });
  
    if (num_denms){
        // Join the array of strings into a single string with commas between each element
        events_string = '[' + denm_info_strings.join('; ') + ']';
    }

    // Function to toggle the switch state
    const toggleSwitch = () => {
        const invertedState = !isOn;
        setIsOn(invertedState);
        props.toggleRsu(props._id, invertedState);
    };

    useEffect(() => {
        setIsOn(props.active);
    }, [props.active]);

    return (
        <WrapperDiv>
            <InfoRsuDiv>
                <div><b>Active Events: </b>{events_string}</div>
                <div><b>Cpu Usage Percentage: </b>{props.message[0].cpuUsagePercentage} %</div>
                <div><b>Free Disk Space Percentage: </b>{props.message[0].freeDiskSpacePercentage} %</div>
                <div><b>Generation Date Time: </b>{parseDate(props.message[0].generationDateTime + 1072915200000 - 5000)}</div>
                <div><b>Last Boot Date Time: </b>{parseDate(props.message[0].lastBootDateTime + 1072915200000 - 5000)}</div>
                <div><b>Memory Usage Percentage: </b>{props.message[0].memoryUsagePercentage} %</div>
                <div><b>Stack Mode: </b>{props.stackMode}</div>
                <div><b>Access Mode: </b>{props.accessMode}</div>
                {isAuthenticated && (<div><b>IP Address: </b>{props.message[0].ipAddress} </div>)}
                {isAuthenticated && (
                    <label style={{ display: 'flex', alignItems: 'center' }}>
                        <b style={{ marginRight: '8px' }}>Visibility:</b>
                        <Switch
                            onChange={toggleSwitch}
                            checked={isOn}
                            height={20}
                            width={40}
                            offColor={'#FF0000'}
                            borderRadius={10}
                        />
                    </label>
                )}
            </InfoRsuDiv>
        </WrapperDiv>
    )
}

const InfoRsuDiv = styled.div`
    text-align: left;
    font-size: 16px;
    line-height: 1.9; 
    padding: 1rem 0.5rem;
    
    &:first-child {
        margin-top: 0; 
    }

    @media (max-width: 768px) {
        font-size: 12px;
    }
`;

const WrapperDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`
export default RsuTabInfo;
