import { useState, useEffect } from 'react';
import { Marker, Popup } from 'react-map-gl';
import { PopupStyle } from './PopupStyle';
import road_events from '../../../utils/road_events';
import denm_images from '../../../utils/resources';
import styled from 'styled-components';
import { FaTimes, FaEdit } from 'react-icons/fa';
import { AuthContext } from '../../Auth/AuthContext';
import { useContext } from 'react';

function Denm({ station_id, latitude, longitude, sub_cause_name, alertList, denmID, onCancelAlert, onUpdateAlert }) {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isCancelInProgress, setCancelInProgress] = useState(false);
  const { isAuthenticated } = useContext(AuthContext);


  useEffect(() => {
    const handleAlertClick = (event) => {
      const clickedAlertId = event.detail;
      if (clickedAlertId === parseInt(denmID)) {
        setPopupOpen((prevToggle) => !prevToggle);
      }
    };

    window.addEventListener('alertClicked', handleAlertClick);

    return () => {
      window.removeEventListener('alertClicked', handleAlertClick);
    };
  }, [denmID]);

  const handleCancelAlert = (alertId) => {
    if (!isCancelInProgress) {
      setCancelInProgress(true);
      onCancelAlert(alertList[alertId]).finally(() => {
        setCancelInProgress(false);
      });
    }
  }

  const handleUpdateAlert = (alertId) => {
    setPopupOpen(false)
    onUpdateAlert(alertList[alertId]);
  }

  return (
    <>
      <Marker offsetLeft={-14} offsetTop={-14} latitude={latitude} longitude={longitude}>
        <DenmMarker>
          <img
            style={{ width: '30px' }}
            src={denm_images[road_events[alertList[denmID].cause_code.toString()]["subCauseCodes"]['0']['imageCode']]}
            onClick={() => setPopupOpen(!isPopupOpen)}
            alt="error"
          />
        </DenmMarker>
      </Marker>
      {isPopupOpen && (
        <PopupStyle>
          <Popup
            latitude={latitude}
            longitude={longitude}
            anchor="bottom"
            closeButton={true}
            closeOnClick={false}
            onClose={() => setPopupOpen(false)}
          >
            <h1>{sub_cause_name}</h1>
            <p>Orig. Station ID: {station_id}</p>
            <p>Latitude: {latitude}</p>
            <p>Longitude: {longitude}</p>
            { isAuthenticated ?
              <ButtonWrapper>
                { station_id === 1 ? // Only DENMs generated by CCAM can be updated
                  <UpdateButton onClick={() => handleUpdateAlert(denmID)}>
                    <UpdateIcon>
                      <FaEdit />
                    </UpdateIcon>
                  </UpdateButton>
                  : null
                }

                <CancelButton onClick={() => handleCancelAlert(denmID)} disabled={isCancelInProgress}>
                  <CancelIcon>
                    <FaTimes />
                  </CancelIcon>
                </CancelButton>
              </ButtonWrapper>
              : null
            }
          </Popup>
        </PopupStyle>
      )}
    </>
  );
}
const DenmMarker = styled.div`
  filter: drop-shadow(1px 0 0 black) drop-shadow(-1px 0 0 black) drop-shadow(0 2px 0 black) drop-shadow(0 -1px 0 black);
`;

const CancelButton = styled.button`
  background-color: lightcoral;
  border: none;
  padding: 0.2rem 0.4rem;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 1.8rem; /* Set the height to adjust the button size */
  width: 1.8rem; /* Set the width to make the button a perfect circle */
`;

const CancelIcon = styled.span`
  font-size: 1.2rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; /* Set the height to occupy the entire button */
`;

const UpdateButton = styled.button`
  background-color: lightblue;
  border: none;
  padding: 0.2rem 0.4rem;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 1.8rem; /* Set the height to adjust the button size */
  width: 1.8rem; /* Set the width to make the button a perfect circle */
`;

const UpdateIcon = styled.span`
  font-size: 1.2rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; /* Set the height to occupy the entire button */
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 0.5rem; 
`;

export default Denm;