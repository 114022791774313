import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from './pages/login_page';
import Sidebar from './components/Sidebar/Sidebar';
import styled from 'styled-components';
import Map_page from './pages/map_page';
import About_page from './pages/about_page';
import ApiToken_Page from './pages/apiToken_page';
import Landing_page from './pages/landing_page';
import Video_page from './pages/video_page';
import EmbedPage from './pages/embed_page';
import Cookies from "js-cookie";
import { FaBars } from 'react-icons/fa';
import './styles/application.css';

export const AuthApi = React.createContext();
export const TokenApi = React.createContext();

const Layout = styled.div`
  width: 100%;
  height: 100%;
`

const Application = ()=> {
  const [auth, setAuth] = useState(false);
  const [token, setToken] = useState("");
  const [toggled, setToggled] = useState(false);

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  const readCookie = () => {
    let token = Cookies.get("token");
    if (token) {
      setAuth(true);
      setToken(token);
    }
  };
  React.useEffect(() => {
    readCookie();
  }, []);

  return (
    <div className="application">
      <AuthApi.Provider value={{ auth, setAuth }}>
        <TokenApi.Provider value={{ token, setToken }}>
          <Router className="Router">

            <Sidebar toggled={toggled} handleToggleSidebar={handleToggleSidebar} />
            <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
              <div className="circle-background">
                <FaBars size={26} color="lightgrey"/>
              </div>
            </div>
              <Switch>
                <Layout>
                  <Route path="/login" component={Login} />
                  <Route path="/about" component={About_page} />
                  <Route path="/map" component={Map_page} />
                  <Route path="/video" component={Video_page} />
                  <Route path="/doc" render={() => <EmbedPage url={process.env.REACT_APP_API_DOC_URL}/>} />
                  <Route path="/token" component={ApiToken_Page} />
                  <Route path="/grafana" render={() => <EmbedPage url={process.env.REACT_APP_GRAFANA_URL}/>} />
                  <Route path="/" exact={true} component={Landing_page} />
                </Layout>
              </Switch>
    
          </Router>
        </TokenApi.Provider>
      </AuthApi.Provider>
    </div>
  );
}

export default Application;