import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { DatePicker, InputGroup, Dropdown } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import GenericDropdown from './Dropdowns/GenericDropdown';

const DateTimeRangePicker = ({handleTimeIntervalChange, handleDateTimeRangeChange, handleWindowChange, activeSection, selectedWindow, resetDropdown, rangeRequired, windowRequired}) => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const [startDateTime, setStartDateTime] = useState(currentDate);
  const [endDateTime, setEndDateTime] = useState(new Date());
  const [showCustomPicker, setShowCustomPicker] = useState(false);
  const [selectedInterval, setSelectedInterval] = useState("Select an option");
  const [windowOptions, setWindowOptions] = useState(["", "1 hour", "1 day", "1 week", "30 days"]);

  const baseIntervalOptions = {
    'hour': 'Last Hour',
    'day': 'Last Day',
    'week': 'Last Week',
    'month': 'Last Month',
    '3months': 'Last 3 Months',
    '6months': 'Last 6 Months',
    'year': 'Last Year',
    'custom': 'Custom'
  };

  // Filter options based on activeSection
  const intervalOptions = (activeSection === 'AvgSpeed' || activeSection === 'AverageMet' || activeSection === 'BoxPlotMet') ?
    Object.fromEntries(Object.entries(baseIntervalOptions).filter(([key]) => key !== 'hour')) :
    baseIntervalOptions;

  const setFixedInterval = (value) => {
    const now = new Date();
    let start = new Date();
    setShowCustomPicker(false); 

    switch (value) {
      case 'hour':
        start = new Date(now.getTime() - 60 * 60 * 1000); 
        break;
      case 'day':
        start.setDate(now.getDate() - 1);
        break;
      case 'week':
        start.setDate(now.getDate() - 7);
        break;
      case 'month':
        start.setMonth(now.getMonth() - 1);
        break;
      case '3months':
        start.setMonth(now.getMonth() - 3);
        break;
      case '6months':
        start.setMonth(now.getMonth() - 6);
        break;
      case 'year':
        start.setFullYear(now.getFullYear() - 1);
        break;
      case 'custom':
        setShowCustomPicker(true); 
        break;
      default:
        return;
    }

    if (value !== 'custom') {
      if(value !== 'hour' && value !== 'day') start.setHours(0, 0, 0, 0); 
      setStartDateTime(start);
      setEndDateTime(now);
    }

    setSelectedInterval(intervalOptions[value]); 
    handleTimeIntervalChange(value);
  };

  const handleStartDateChange = (date) => {
    setStartDateTime(date);
    handleDateTimeRangeChange(date, endDateTime); 
  };

  const handleEndDateChange = (date) => {
    setEndDateTime(date);
    handleDateTimeRangeChange(startDateTime, date); 
  };

  // Function to disable dates after the selected endDateTime
  const disableStartDate = (date) => {
    return date > new Date() || date > endDateTime || date < new Date(2023, 9, 3);
  };

  // Function to disable dates before the selected startDateTime or after the current date
  const disableEndDate = (date) => {
    return date > new Date() || date < startDateTime;
  };

  useEffect(() => {
    if (resetDropdown){ 
      setShowCustomPicker(false);
      setSelectedInterval(intervalOptions[0] || "");
    }
  }, [resetDropdown, intervalOptions]);

  useEffect(() => {
    handleDateTimeRangeChange(startDateTime, endDateTime);
  }, [selectedInterval]);

  useEffect(() => {
    const diffMs = endDateTime - startDateTime; // difference in milliseconds
    const diffHours = diffMs / (1000 * 60 * 60); // convert to hours

    if (diffHours <= 1) {
      setWindowOptions(["", "10 minutes"]);
    } else if (diffHours <= 4) {
      setWindowOptions(["", "10 minutes", "1 hour"]);
    } else if (diffHours <= 48) {
      setWindowOptions(["", "1 hour", "3 hours", "6 hours"]);
    } else if (diffHours <= 72) {
      setWindowOptions(["", "3 hours", "6 hours", "12 hours", "1 day"]);
    } else if (diffHours <= 168) { // 1 week
      setWindowOptions(["", "6 hours", "12 hours", "1 day"]);
    } else if (diffHours <= 1056) { // 1 month and a half
      setWindowOptions(["", "1 day", "1 week"]);
    } else if (diffHours <= 4320) { // 6 months
      setWindowOptions(["", "1 week", "30 days"]);
    } else {
      setWindowOptions(["", "30 days", "90 days"]);
    }

    handleWindowChange("");
  }, [startDateTime, endDateTime]);

  const handleWindowSelectionChange = (selectedOption) => {
    const influxDBMapping = {
      "10 minutes": "10m",
      "1 hour": "1h",
      "3 hours": "3h",
      "6 hours": "6h",
      "12 hours": "12h",
      "1 day": "1d",
      "1 week": "7d",
      "30 days": "30d",
      "90 days": "90d"
    };
  
    const isSpecialSection = activeSection === 'AverageMet' || activeSection === 'BoxPlotMet';
    const valueToSend = isSpecialSection && influxDBMapping[selectedOption] ? influxDBMapping[selectedOption] : selectedOption;
  
    handleWindowChange(valueToSend); // Call the parent handler with the modified value
  };
  
  return (
    <div style={{marginRight: "10px"}}>
      <InputGroup style={{ width: '100%' }}>
        {/* <InputGroup.Addon>Range:</InputGroup.Addon> */}
        <InputGroup.Addon>
            <Label required={rangeRequired}>
              {rangeRequired ? '*' : ''}Range:
            </Label>
          </InputGroup.Addon>
        <Dropdown title={selectedInterval || "Select an option"} onSelect={setFixedInterval}>
          {Object.entries(intervalOptions).map(([key, label]) => (
            <Dropdown.Item eventKey={key} key={key}>{label}</Dropdown.Item>
          ))}
        </Dropdown>
        {showCustomPicker && (
          <>
            <DatePicker
              format="dd-MM-yyyy HH:mm"
              value={startDateTime}
              onChange={handleStartDateChange}
              showMeridian={false}
              shouldDisableDate={disableStartDate}
              style={{ width: 165 }}
            />
            <InputGroup.Addon>-</InputGroup.Addon>
            <DatePicker
              format="dd-MM-yyyy HH:mm"
              value={endDateTime}
              onChange={handleEndDateChange}
              showMeridian={false}
              shouldDisableDate={disableEndDate}
              style={{ width: 165 }}
            />

            <GenericDropdown
              label={"Window"}
              options={windowOptions}
              onSelectChange={handleWindowSelectionChange}
              selected={selectedWindow}
              resetDropdown={resetDropdown}
              isRequired={windowRequired}
            />
          </>
        )}
      </InputGroup>
    </div>
  );
}

const Label = styled.label`
  font-size: 14px;
  ${props => props.required && css`
    color: black; 
  `}
`;

export default DateTimeRangePicker;



