import React, { useState } from 'react'
import { Marker, Popup } from 'react-map-gl'
import { PopupStyle } from './PopupStyle'
import styled from 'styled-components';
import ObjectIcon from './ObjectIcon'; 

const VsmMarker = styled.div`
    cursor: pointer;
    overflow: hidden;
    background: lightgreen;
    border-radius: 50%;
    outline-style: solid;
    outline-width: 2px;
    outline-color: black;
`;

function Vsm(props) {
    const { id, ...markerProps } = props
    const [showPopup, togglePopup] = useState(false)

    const MarkerPopup = () =>
        <PopupStyle>
            <Popup
                latitude={props.latitude}
                longitude={props.longitude}
                anchor="bottom"
                className="popup"
                closeButton={true}
                dynamicPosition={false}
                sortByDepth={true}
                closeOnClick={false}
                onClose={() => togglePopup(!showPopup)}
            >
                <br></br><h5><b>ID:</b> {props.stationId}</h5><br></br>
                <p><b>Latitude:</b> {props.latitude}</p>
                <p><b>Longitude:</b> {props.longitude}</p>
                <p><b>Illuminance:</b> {(props.illuminance !== undefined && props.illuminance !== null) ? Number(props.illuminance).toFixed(2) + " lx" : 'No data'} </p>
                <p><b>Temperature:</b> {(props.temperature !== undefined && props.temperature !== null) ? Number(props.temperature).toFixed(2) + " ºC" : 'No data'} </p>
                <p><b>Pressure:</b> {(props.pressure !== undefined && props.pressure !== null) ? Number(props.pressure).toFixed(2) + " hPa" : 'No data'} </p>
                <p><b>Humidity:</b> {(props.humidity !== undefined && props.humidity !== null) ? Number(props.humidity).toFixed(2) + " %" : 'No data'} </p>
            </Popup>
        </PopupStyle>

    return (
        <>
            <Marker {...markerProps} offsetLeft={-12.5} >
                <VsmMarker onClick={e => {
                    e.preventDefault()
                    togglePopup(true)
                }}>    
                    <ObjectIcon/>
                </VsmMarker>
            </Marker>
            {showPopup &&
                <MarkerPopup />
            }
        </>
    )
}

export default Vsm