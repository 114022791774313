import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import axios from 'axios';

function RsuMessageList({ rsu_id }) {
    const [messages, setMessages] = useState([]);
    const [expandedIndexes, setExpandedIndexes] = useState([]);

    useEffect(() => {
      async function getHeartbeats() {
        try {
          const response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_PREFIX}/rsu/${rsu_id}/message`,
            headers: {
              Authorization: process.env.REACT_APP_API_PUBLIC_TOKEN,
            }
          });

          const responseData = response.data;

          const manipulatedData = responseData.message.map(item => ({
            generationDateTime: (item.generationDateTime  + 1072915200000 - 5000),
            cpuUsagePercentage: item.cpuUsagePercentage,
            memoryUsagePercentage: item.memoryUsagePercentage,
            activeDENM: item.activeDENM,
            freeDiskSpacePercentage: item.freeDiskSpacePercentage,
            lastBootDateTime: (item.lastBootDateTime + 1072915200000 - 5000)
          }));

          setMessages(manipulatedData);

          if (Array.isArray(manipulatedData) && manipulatedData.length > 0) {
            const initialExpandedState = Array(manipulatedData.length).fill(
              false
            );
            setExpandedIndexes(initialExpandedState);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }

      getHeartbeats();
    }, [rsu_id]);

    const toggleExpand = index => {
      const updatedExpandedIndexes = [...expandedIndexes];
      updatedExpandedIndexes[index] = !updatedExpandedIndexes[index];
      setExpandedIndexes(updatedExpandedIndexes);
    };

    function parseDate(obj) {
      const date = new Intl.DateTimeFormat('pt-PT', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
      }).format(obj)
      return date
    }

    const parseActiveDENM = activeDENM => {
      const activeDENMArray = activeDENM;
      let events_string = "None";
    
      if (activeDENMArray) {
        try {
          if (Array.isArray(activeDENMArray) && activeDENMArray.length > 0) {
            const denm_info_strings = activeDENMArray.map(denm => {
              const originatingStationID = denm.originatingStationID;
              const sequenceNumber = denm.sequenceNumber;
              const referenceTimeOriginal = denm.referenceTime;
              const formattedTime = parseDate(referenceTimeOriginal + 1072915200000 - 5000);
              
              return `${originatingStationID}, ${sequenceNumber}, ${formattedTime}`;
            });
    
            events_string = '[' + denm_info_strings.join('; ') + ']';
          }
        } catch (error) {
          console.error("Error parsing activeDENM:", error);
        }
      }
    
      return events_string;
    };

    return (
      <div>
        {messages?.length > 0 ? (
          messages.map((message, index) => (
            <MessageContainer
              key={index}
              highlighted={expandedIndexes[index]}
              onClick={() => toggleExpand(index)}
            >
              <div>
                <b>Message #{index}</b>
                <br />
                <b>Generation Date Time: </b>{parseDate(message.generationDateTime)}
              </div>
              {expandedIndexes[index] && (
                <div>
                  <b>Active Events: </b> {parseActiveDENM(message.activeDENM)}
                  <br />
                  <b>CPU Usage Percentage: </b>{message.cpuUsagePercentage}%
                  <br />
                  <b>Free Disk Space Percentage: </b>{message.freeDiskSpacePercentage}%
                  <br />
                  <b>Last Boot Date Time: </b>{parseDate(message.lastBootDateTime)}
                  <br />
                  <b>Memory Usage Percentage: </b>{message.memoryUsagePercentage}%
                </div>
              )}
            </MessageContainer>
          ))
        ) : (
          <div>No messages available.</div>
        )}
      </div>
    );
}

const MessageContainer = styled.div`
  padding: 10px;
  border: 1px solid #ccc;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  animation: ${props => (props.highlighted ? pulseAnimation : 'none')};
  background-color: ${props => (props.highlighted ? '#f0f0f0' : 'initial')};
  text-align: left;

  &:hover {
    animation: pulseAnimation 1.5s infinite alternate;
    background-color: #f0f0f0;
  }
`;

// Define the pulse animation using keyframes
const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
`;


export default RsuMessageList;


