import React, { useState } from 'react'
import { Marker, Popup } from 'react-map-gl'
import { PopupStyle } from './PopupStyle'
import styled from 'styled-components';
import ObjectIcon from './ObjectIcon'; 

const VruMarker = styled.div`
    cursor: pointer;
    overflow: hidden;
    background: orange;
    border-radius: 50%;
    outline-style: solid;
    outline-width: 2px;
    outline-color: black;
`;

// Station Type: pedestrian(1), bicyclist(2), moped(3), motorcycle(4), lightVRUvehicle(12), or animal(13)

function Vam(props) {
    const { id, ...markerProps } = props
    const [showPopup, togglePopup] = useState(false)
    const stationTypes = {
        1: "pedestrian",
        2: "cyclist",
        3: "moped",
        4: "motorcycle",
        12: "lightVRUvehicle",
        13: "animal",
    };

    let stationTypeName = stationTypes[props.station_type] || "unknown";

    const MarkerPopup = () =>
        <PopupStyle>
            <Popup
                latitude={props.latitude}
                longitude={props.longitude}
                anchor="bottom"
                className="popup"
                closeButton={true}
                dynamicPosition={false}
                sortByDepth={true}
                closeOnClick={false}
                onClose={() => togglePopup(!showPopup)}
            >
                <br></br><h5><b>ID:</b> {props.stationId}</h5><br></br>
                <p>Latitude: {props.latitude}</p>
                <p>Longitude: {props.longitude}</p>
            </Popup>
        </PopupStyle>

    return (
        <>
            <Marker {...markerProps} offsetLeft={-12.5} >
                <VruMarker onClick={e => {
                    e.preventDefault()
                    togglePopup(true)
                }}>    
                    {/* <BiWalk size={20} color="black" display="block" margin-left= "auto" margin-right= "auto" onClick={() => togglePopup(!showPopup)}/> */}
                    <ObjectIcon  subClassName={stationTypeName}/>
                </VruMarker>
            </Marker>
            {showPopup &&
                <MarkerPopup />
            }
        </>
    )
}

export default Vam