const road_events = {
  "1": {
    "tag": "trafficCondition1",
    "subCauseCodes": {
      "0": { "imageCode": "traffic", "name": { "en": "Traffic", "pt": "Congestionamento" } },
      "1": { "imageCode": "traffic", "name": { "en": "Increased Traffic Volume", "pt": "Volume de tráfego aumentado" } },
      "2": { "imageCode": "traffic", "name": { "en": "Traffic Jam Slowly Increasing", "pt": "Congestionamento a aumentar lentamente" } },
      "3": { "imageCode": "traffic", "name": { "en": "Traffic Jam Increasing", "pt": "Congestionamento a aumentar" } },
      "4": { "imageCode": "traffic", "name": { "en": "Traffic Jam Strongly Increasing", "pt": "Congestionamento a aumentar rapidamente" } },
      "5": { "imageCode": "traffic", "name": { "en": "Stationary Traffic", "pt": "Trânsito parado" } },
      "6": { "imageCode": "traffic", "name": { "en": "Traffic Jam Slightly Decreasing", "pt": "Congestionamento a diminuir lentamente" } },
      "7": { "imageCode": "traffic", "name": { "en": "Traffic Jam Decreasing", "pt": "Congestionamento a diminuir" } },
      "8": { "imageCode": "traffic", "name": { "en": "Traffic Jam Strongly Decreasing", "pt": "Congestionamento a diminuir rapidamente" } }
    }
  },
  "2": {
    "tag": "accident2",
    "subCauseCodes": {
      "0": { "imageCode": "accident", "name": { "en": "Accident", "pt": "Acidente" } },
      "1": { "imageCode": "accident", "name": { "en": "Multi-Vehicle Accident", "pt": "Acidente com múltiplos veículos" } },
      "2": { "imageCode": "accident", "name": { "en": "Heavy Accident", "pt": "Acidente com Pesados" } },
      "3": { "imageCode": "accident", "name": { "en": "Accident involving Lorry", "pt": "Acidente com camião" } },
      "4": { "imageCode": "accident", "name": { "en": "Accident involving Bus", "pt": "Acidente com autocarro" } },
      "5": { "imageCode": "accident", "name": { "en": "Accident involving Hazardous Materials", "pt": "Acidente com matérias perigosas" } },
      "6": { "imageCode": "accident", "name": { "en": "Accident on Opposite Lanes", "pt": "Acidente no sentido oposto" } },
      "7": { "imageCode": "accident", "name": { "en": "Unsecured Accident", "pt": "Acidente não protegido" } },
      "8": { "imageCode": "accident", "name": { "en": "Accident with Assistance Requested", "pt": "Acidente com pedido de Assistência" } }
    }
  },

  "3": {
    "tag": "roadworks3",
    "subCauseCodes": {
      "0": { "imageCode": "road_works", "name": { "en": "Roadworks", "pt": "Trabalhos" } },
      "1": { "imageCode": "road_works", "name": { "en": "Major Roadworks", "pt": "Trabalhos pesados" } },
      "2": { "imageCode": "road_works", "name": { "en": "Road Marking Works", "pt": "Trabalhos de marcação de vias" } },
      "3": { "imageCode": "road_works", "name": { "en": "Slow Moving Road Maintenance", "pt": "Trabalhos móveis" } },
      "4": { "imageCode": "road_works", "name": { "en": "Short term Stationary Roadworks", "pt": "Trabalhos de curta duração" } },
      "5": { "imageCode": "road_works", "name": { "en": "Street cleaning", "pt": "Limpeza da via" } },
      "6": { "imageCode": "road_works", "name": { "en": "Winter service", "pt": "Limpeza de neve" } }
    }
  },

  "6": {
    "tag": "adverseWeatherCondition-Adhesion6",
    "subCauseCodes": {
      "0": { "imageCode": "slippery_road", "name": { "en": "Slippery Road", "pt": "Piso escorregadio" } },
      "1": { "imageCode": "slippery_road", "name": { "en": "Heavy Frost on the Road", "pt": "Geada na estrada" } },
      "2": { "imageCode": "slippery_road", "name": { "en": "Fuel on the Road", "pt": "Derrame de combustível" } },
      "3": { "imageCode": "slippery_road", "name": { "en": "Mud on the Road", "pt": "Lama" } },
      "4": { "imageCode": "slippery_road", "name": { "en": "Snow on the Road", "pt": "Neve na estrada" } },
      "5": { "imageCode": "slippery_road", "name": { "en": "Ice on the Road", "pt": "Gelo" } },
      "6": { "imageCode": "slippery_road", "name": { "en": "Black Ice on the Road", "pt": "Gelo Negro na estrada" } },
      "7": { "imageCode": "slippery_road", "name": { "en": "Oil on the Road", "pt": "Petróleo na estrada" } },
      "8": { "imageCode": "slippery_road", "name": { "en": "Loose Chippings", "pt": "Cascalho na estrada" } },
      "9": { "imageCode": "slippery_road", "name": { "en": "Instant Black Ice", "pt": "Gelo Negro instantâneo na estrada" } },
      "10": { "imageCode": "slippery_road", "name": { "en": "Road Salted", "pt": "Estrada com sal" } }
    }
  },

  "9": {
    "tag": "hazardousLocation-SurfaceCondition9",
    "subCauseCodes": {
      "0": { "imageCode": "road_surface_risk", "name": { "en": "Road surface risk", "pt": "Perigo na estrada" } },
      "1": { "imageCode": "road_surface_risk", "name": { "en": "Rockfalls", "pt": "Queda de pedras" } },
      "2": { "imageCode": "road_surface_risk", "name": { "en": "Earthquake damage", "pt": "Danos de tremor de terra" } },
      "3": { "imageCode": "road_surface_risk", "name": { "en": "Sewer Collapse", "pt": "Esgotos abatidos" } },
      "4": { "imageCode": "road_surface_risk", "name": { "en": "Subsidence", "pt": "Abatimento" } },
      "5": { "imageCode": "road_surface_risk", "name": { "en": "Snow drifts", "pt": "Vento nevado" } },
      "6": { "imageCode": "road_surface_risk", "name": { "en": "Storm Damage", "pt": "Danos de tempestade" } },
      "7": { "imageCode": "road_surface_risk", "name": { "en": "Burst Pipe", "pt": "Cano rebentado" } },
      "8": { "imageCode": "road_surface_risk", "name": { "en": "Volcano Eruption", "pt": "Erupção Vulcânica" } },
      "9": { "imageCode": "road_surface_risk", "name": { "en": "Falling ice", "pt": "Gelo a cair" } }
    }
  },

  "10": { // Danger sign... didn't find a more appropriate one
    "tag": "hazardousLocation-ObstacleOnTheRoad10",
    "subCauseCodes": {
      "0": { "imageCode": "road_obstacle", "name": { "en": "Obstacle on the road", "pt": "Obstáculo" } },
      "1": { "imageCode": "road_obstacle", "name": { "en": "Shed Load", "pt": "Carga na estrada" } },
      "2": { "imageCode": "road_obstacle", "name": { "en": "Parts of Vehicles", "pt": "Peças de veículos" } },
      "3": { "imageCode": "road_obstacle", "name": { "en": "Parts of Tyres", "pt": "Rasto de pneu" } },
      "4": { "imageCode": "road_obstacle", "name": { "en": "Big Object on the road", "pt": "Objeto de grandes dimensões na estrada" } },
      "5": { "imageCode": "road_obstacle", "name": { "en": "Fallen Tree", "pt": "Árvore caída" } },
      "6": { "imageCode": "road_obstacle", "name": { "en": "Hub Caps", "pt": "Tampões" } },
      "7": { "imageCode": "road_obstacle", "name": { "en": "Waiting vehicles", "pt": "Veículo parado" } }
    }
  },

  "11": {
    "tag": "hazardousLocation-AnimalOnTheRoad11",
    "subCauseCodes": {
      "0": { "imageCode": "animals_on_the_road", "name": { "en": "Animal on the road", "pt": "Animal na estrada" } },
      "1": { "imageCode": "animals_on_the_road", "name": { "en": "Wild Animal on the road", "pt": "Animal selvagem na estrada" } },
      "2": { "imageCode": "animals_on_the_road", "name": { "en": "Herd of animals on the road", "pt": "Rebanho na estrada" } },
      "3": { "imageCode": "animals_on_the_road", "name": { "en": "Small animal on the road", "pt": "Animal pequeno na estrada" } },
      "4": { "imageCode": "animals_on_the_road", "name": { "en": "Large animal on the road", "pt": "Animal grande na estrada" } }
    }
  },

  "12": {
    "tag": "humanPresenceOnTheRoad12",
    "subCauseCodes": {
      "0": { "imageCode": "people_on_the_road", "name": { "en": "Human present on the road", "pt": "Humano na estrada" } },
      "1": { "imageCode": "people_on_the_road", "name": { "en": "Children on roadway", "pt": "Criança na estrada" } },
      "2": { "imageCode": "people_on_the_road", "name": { "en": "Cyclist on roadway", "pt": "Ciclista na estrada" } },
      "3": { "imageCode": "people_on_the_road", "name": { "en": "Motorcyclist on roadway", "pt": "Motociclista na estrada" } }
    }
  },

  "14": {
    "tag": "wrongWayDriving14",
    "subCauseCodes": {
      "0": { "imageCode": "wrong_way", "name": { "en": "Wrong way driving", "pt": "Contramão" } },
      "1": { "imageCode": "wrong_way", "name": { "en": "Wrong lane driving", "pt": "Condução na via errada" } },
      "2": { "imageCode": "wrong_way", "name": { "en": "Wrong direction driving", "pt": "Contramão" } }
    }
  },

  "15": {
    "tag": "rescueAndRecoveryWorkInProgress15",
    "subCauseCodes": {
      "0": { "imageCode": "emergency_vehicles", "name": { "en": "Rescue and Recovery Work in Progress", "pt": "Resgate e Recuperação em curso" } },
      "1": { "imageCode": "emergency_vehicles", "name": { "en": "Emergency Vehicle", "pt": "Veículo de Emergência" } },
      "2": { "imageCode": "emergency_vehicles", "name": { "en": "Rescue Helicopter Landing", "pt": "Helicóptero a aterrar" } },
      "3": { "imageCode": "emergency_vehicles", "name": { "en": "Police Activity Ongoing", "pt": "Atividade policial em curso" } },
      "4": { "imageCode": "emergency_vehicles", "name": { "en": "Medical Emergency Ongoing", "pt": "Emergência médica em curso" } },
      "5": { "imageCode": "emergency_vehicles", "name": { "en": "Child Abduction in Progress", "pt": "Rapto de criança em curso" } }
    }
  },

  "17": {
    "tag": "adverseWeatherCondition-ExtremeWeatherCondition17",
    "subCauseCodes": {
      "0": { "imageCode": "bad_weather", "name": { "en": "Extreme Weather", "pt": "Meteorologia Extrema" } },
      "1": { "imageCode": "bad_weather", "name": { "en": "Strong Winds", "pt": "Vento Forte" } },
      "2": { "imageCode": "bad_weather", "name": { "en": "Damaging Hail", "pt": "Granizo muito forte" } },
      "3": { "imageCode": "bad_weather", "name": { "en": "Hurricane", "pt": "Furação" } },
      "4": { "imageCode": "bad_weather", "name": { "en": "Thunderstorm", "pt": "Trovoada" } },
      "5": { "imageCode": "bad_weather", "name": { "en": "Tornado", "pt": "Tornado" } },
      "6": { "imageCode": "bad_weather", "name": { "en": "Blizzard", "pt": "Tempestade" } }
    }
  },

  "18": {
    "tag": "adverseWeatherCondition-Visibility18",
    "subCauseCodes": {
      "0": { "imageCode": "low_visibility", "name": { "en": "Low Visibility", "pt": "Baixa visibilidade" } },
      "1": { "imageCode": "low_visibility", "name": { "en": "Fog", "pt": "Nevoeiro" } },
      "2": { "imageCode": "low_visibility", "name": { "en": "Smoke", "pt": "Fumo" } },
      "3": { "imageCode": "low_visibility", "name": { "en": "Heavy Snowfall", "pt": "Nevão" } },
      "4": { "imageCode": "low_visibility", "name": { "en": "Heavy Rain", "pt": "Chuva forte" } },
      "5": { "imageCode": "low_visibility", "name": { "en": "Heavy Hail", "pt": "Granizo forte" } },
      "6": { "imageCode": "low_visibility", "name": { "en": "Low Sun Glare", "pt": "Sol baixo" } },
      "7": { "imageCode": "low_visibility", "name": { "en": "Sandstorm", "pt": "Tempestade de areia" } },
      "8": { "imageCode": "low_visibility", "name": { "en": "Swarm of Insects", "pt": "Nuvem de insetos" } }
    }
  },

  "19": {
    "tag": "adverseWeatherCondition-Precipitation19",
    "subCauseCodes": {
      "0": { "imageCode": "precipitation", "name": { "en": "Precipitation", "pt": "Precipitação" } },
      "1": { "imageCode": "precipitation", "name": { "en": "Heavy rain", "pt": "Chuva forte" } },
      "2": { "imageCode": "precipitation", "name": { "en": "Heavy Snowfall", "pt": "Nevão" } },
      "3": { "imageCode": "precipitation", "name": { "en": "Soft Hail", "pt": "Granizo" } }
    }
  },

  "26": {
    "tag": "slowVehicle26",
    "subCauseCodes": {
      "0": { "imageCode": "slow_vehicle", "name": { "en": "Slow vehicle", "pt": "Veículo lento" } },
      "1": { "imageCode": "slow_vehicle", "name": { "en": "Slow Maintenance vehicle", "pt": "Veículo de manutenção lento" } },
      "2": { "imageCode": "slow_vehicle", "name": { "en": "Vehicle Slowing to Look at Accident", "pt": "Veículo a abrandar para ver acidente" } },
      "3": { "imageCode": "slow_vehicle", "name": { "en": "Slow vehicle with Abnormal Load", "pt": "Veículo lento com carga anormal" } },
      "4": { "imageCode": "slow_vehicle", "name": { "en": "Slow vehicle with Abnormal Wide Load", "pt": "Veículo lento com carga anormalmente larga" } },
      "5": { "imageCode": "slow_vehicle", "name": { "en": "Slow vehicle convoy", "pt": "Comboio de veículos lentos" } },
      "6": { "imageCode": "slow_vehicle", "name": { "en": "Slow snowplough", "pt": "Limpaneves lento" } },
      "7": { "imageCode": "slow_vehicle", "name": { "en": "Slow deicing vehicle", "pt": "Limpaneves lento" } },
      "8": { "imageCode": "slow_vehicle", "name": { "en": "Slow salting vehicle", "pt": "Limpaneves lento" } }
    }
  },

  "27": {
    "tag": "dangerousEndOfQueue27",
    "subCauseCodes": {
      "0": { "imageCode": "lane_loss", "name": { "en": "Dangerous end of Queue", "pt": "Fim de fila perigoso" } },
      "1": { "imageCode": "lane_loss", "name": { "en": "Sudden end of Queue", "pt": "Fim de fila súbito" } },
      "2": { "imageCode": "lane_loss", "name": { "en": "Queue over the hill", "pt": "Fila a seguir à lomba" } },
      "3": { "imageCode": "lane_loss", "name": { "en": "Queue around the bend", "pt": "Fila a seguir à curva" } },
      "4": { "imageCode": "lane_loss", "name": { "en": "Queue in tunnel", "pt": "Fila no túnel" } }
    }
  },

  "91": {
    "tag": "vehicleBreakdown91",
    "subCauseCodes": {
      "0": { "imageCode": "vehicle_breakdown", "name": { "en": "Vehicle breakdown", "pt": "Veículo avariado" } },
      "1": { "imageCode": "vehicle_breakdown", "name": { "en": "Vehicle lacking fuel", "pt": "Veículo sem combustível" } },
      "2": { "imageCode": "vehicle_breakdown", "name": { "en": "Vehicle lacking battery power", "pt": "Veículo sem bateria" } },
      "3": { "imageCode": "vehicle_breakdown", "name": { "en": "Vehicle with engine problem", "pt": "Veículo com problema de motor" } },
      "4": { "imageCode": "vehicle_breakdown", "name": { "en": "Vehicle with transmission problem", "pt": "Veículo com problema de transmissão" } },
      "5": { "imageCode": "vehicle_breakdown", "name": { "en": "Vehicle with engine cooling problem", "pt": "Veículo com problema de refrigeração do motor" } },
      "6": { "imageCode": "vehicle_breakdown", "name": { "en": "Vehicle with braking problem", "pt": "Veículo com problema de travagem" } },
      "7": { "imageCode": "vehicle_breakdown", "name": { "en": "Vehicle with steering problem", "pt": "Veículo com problema de direção" } },
      "8": { "imageCode": "vehicle_breakdown", "name": { "en": "Vehicle with tyre puncture", "pt": "Veículo com furo no pneu" } }
    }
  },

  "92": {
    "tag": "postCrash92",
    "subCauseCodes": {
      "0": { "imageCode": "post_accident", "name": { "en": "Post crash", "pt": "Pós Acidente" } },
      "1": { "imageCode": "post_accident", "name": { "en": "Accident without ECall triggered", "pt": "Acidente sem chamada ECall" } },
      "2": { "imageCode": "post_accident", "name": { "en": "Accident with ECall manually triggered", "pt": "Acidente com chamada ECall manual" } },
      "3": { "imageCode": "post_accident", "name": { "en": "Accident with ECall automatically triggered", "pt": "Acidente com chamada ECall automática" } },
      "4": { "imageCode": "post_accident", "name": { "en": "Accident with ECall triggered without Cell access", "pt": "Acidente com chamada ECall sem rede" } }
    }
  },

  "93": {
    "tag": "humanProblem93",
    "subCauseCodes": {
      "0": { "imageCode": "human_problem", "name": { "en": "Human Problem", "pt": "Problema humano" } },
      "1": { "imageCode": "human_problem", "name": { "en": "Glycemia problem", "pt": "Problema de glicémia" } },
      "2": { "imageCode": "human_problem", "name": { "en": "Heart problem", "pt": "Problema cardíaco" } }
    }
  },

  "94": {
    "tag": "stationaryVehicle94",
    "subCauseCodes": {
      "0": { "imageCode": "stopped_vehicle", "name": { "en": "Stationary Vehicle", "pt": "Veículo parado" } },
      "1": { "imageCode": "stopped_vehicle", "name": { "en": "Stationary Vehicle - human problem", "pt": "Veículo parado - problema humano" } },
      "2": { "imageCode": "stopped_vehicle", "name": { "en": "Brokendown Vehicle", "pt": "Veículo avariado" } },
      "3": { "imageCode": "stopped_vehicle", "name": { "en": "Stationary Vehicle after Crash", "pt": "Veículo parado após acidente" } },
      "4": { "imageCode": "stopped_vehicle", "name": { "en": "Stopped Public Transport", "pt": "Transporte público parado" } },
      "5": { "imageCode": "stopped_vehicle", "name": { "en": "Vehicle carrying Dangerous Goods stopped", "pt": "Veículo com matérias perigosas parado" } }
    }
  },

  "95": {
    "tag": "emergencyVehicleApproaching95",
    "subCauseCodes": {
      "0": { "imageCode": "emergency_vehicles", "name": { "en": "Emergency Vehicle Approaching", "pt": "Aproxima-se veículo de emergência" } },
      "1": { "imageCode": "emergency_vehicles", "name": { "en": "Emergency Vehicle Approaching", "pt": "Aproxima-se veículo de emergência" } },
      "2": { "imageCode": "emergency_vehicles", "name": { "en": "Prioritized Vehicle Approaching", "pt": "Aproxima-se Veículo Prioritário" } }
    }
  },

  "96": {
    "tag": "hazardousLocation-DangerousCurve96",
    "subCauseCodes": {
      "0": { "imageCode": "dangerous_curve", "name": { "en": "Dangerous curve", "pt": "Curva perigosa" } },
      "1": { "imageCode": "dangerous_curve", "name": { "en": "Dangerous left turn curve", "pt": "Curva perigosa à esquerda" } },
      "2": { "imageCode": "dangerous_curve", "name": { "en": "Dangerous right turn curve", "pt": "Curva perigosa à direita" } },
      "3": { "imageCode": "dangerous_curve", "name": { "en": "Multiple dangerous curves", "pt": "Várias curvas perigosas" } },
      "4": { "imageCode": "dangerous_curve", "name": { "en": "Dangerous curves starting with left turn curve", "pt": "Várias curva perigosas começando por curva à esquerda" } },
      "5": { "imageCode": "dangerous_curve", "name": { "en": "Dangerous curves starting with right turn curve", "pt": "Várias curva perigosas começando por curva à direita" } }
    }
  },

  "97": {
    "tag": "collisionRisk97",
    "subCauseCodes": {
      "0": { "imageCode": "collision_risk", "name": { "en": "Collision Risk", "pt": "Risco de colisão" } },
      "1": { "imageCode": "collision_risk", "name": { "en": "Collision Risk - Emergency Brake", "pt": "Risco de colisão - Travagem de emergência" } },
      "2": { "imageCode": "collision_risk", "name": { "en": "Collision Risk - Pre Crash system engaged", "pt": "Risco de colisão - Sistema pré-colisão ativado" } },
      "3": { "imageCode": "collision_risk", "name": { "en": "Collision Risk - ESP engaged", "pt": "Risco de colisão - ESP ativado" } },
      "4": { "imageCode": "collision_risk", "name": { "en": "Collision Risk - ABS engaged", "pt": "Risco de colisão - ABS ativado" } },
      "5": { "imageCode": "collision_risk", "name": { "en": "Collision Risk - AEB engaged", "pt": "Risco de colisão - AEB ativado" } },
      "6": { "imageCode": "collision_risk", "name": { "en": "Collision Risk - Brake Warning", "pt": "Risco de colisão - Aviso de travagem" } },
      "7": { "imageCode": "collision_risk", "name": { "en": "Collision Risk - Warning", "pt": "Risco de colisão - Aviso" } }
    }
  },

  "98": {
    "tag": "signalViolation98",
    "subCauseCodes": {
      "0": { "imageCode": "sign_violation", "name": { "en": "Signal Violation", "pt": "Violação de sinalética" } },
      "1": { "imageCode": "sign_violation", "name": { "en": "Sign Violation", "pt": "VIolação de sinal de trânsito" } },
      "2": { "imageCode": "sign_violation", "name": { "en": "Traffic Light Violation", "pt": "Violação de semáforo" } },
      "3": { "imageCode": "sign_violation", "name": { "en": "Traffic Regulation Violation", "pt": "Violação do código da estrada" } }
    }
  },
  
  "99": {
    "tag": "dangerousSituation99",
    "subCauseCodes": {
      "0": { "imageCode": "danger", "name": { "en": "Dangerous Situation", "pt": "Perigo" } },
      "1": { "imageCode": "danger", "name": { "en": "Danger - Emergency Brake", "pt": "Perigo - Travagem de emergência" } },
      "2": { "imageCode": "danger", "name": { "en": "Danger - Pre Crash system engaged", "pt": "Perigo - Sistema pré-colisão ativado" } },
      "3": { "imageCode": "danger", "name": { "en": "Danger - ESP engaged", "pt": "Perigo - ESP ativado" } },
      "4": { "imageCode": "danger", "name": { "en": "Danger - ABS engaged", "pt": "Perigo - ABS ativado" } },
      "5": { "imageCode": "danger", "name": { "en": "Danger - AEB engaged", "pt": "Perigo - AEB ativado" } },
      "6": { "imageCode": "danger", "name": { "en": "Danger - Brake Warning", "pt": "Perigo - Aviso de travagem" } },
      "7": { "imageCode": "danger", "name": { "en": "Danger - Collision Risk Warning", "pt": "Perigo - Risco de colisão" } }
    }
  }
}

module.exports = road_events;