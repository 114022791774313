import { React, useState, useEffect} from "react";
import YoutubeEmbed from '../components/Video/YoutubeEmbed'; 
import '../styles/generic_menu.css';
import '../styles/constants.css';
import v2x_background from '../static/background2.jpg';
import channel_logo from '../static/channel_logo.jpg';
import axios from "axios";


const Video_page = () => {
  const [videos, setVideos] = useState([]);

  const handleScroll = () => {
    window.scrollTo({
      top: window.scrollY + 1,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const apiKey = process.env.REACT_APP_YT_API_KEY; 
        const channelId = 'UCPhXlmpMaQOelQS5U71xg0Q'; 
        const response = await axios.get(
          `https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=${channelId}&maxResults=5&order=date&type=video&key=${apiKey}`
        );
        setVideos(response.data.items);
      } 
      catch (error) {
        console.error('Error fetching videos:', error);
      }
    };

    fetchVideos();
  }, []);

  return (
    <div className="scrollable-background" style={{ backgroundImage: `url(${v2x_background})` }}>
      <div className="white-box-content large-info">
        <div className="logo-title-container">
          <a href="https://www.youtube.com/channel/UCPhXlmpMaQOelQS5U71xg0Q" target="_blank" rel="noopener noreferrer">
            <img src={channel_logo} alt="Channel Logo" className="channel-logo" />
          </a>
          <h1>
            <a class="href-title" href="https://www.youtube.com/channel/UCPhXlmpMaQOelQS5U71xg0Q" target="_blank" rel="noopener noreferrer">
              IT - Embedded Systems Channel
            </a>
          </h1>
        </div>
        <div className="video-container">
          {videos.map((video, index) => (
            <div key={index} className="video-item">
              <YoutubeEmbed video={video}/>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Video_page;
