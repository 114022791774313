import React, { useEffect } from 'react';
import * as d3 from 'd3';

function ChartAvgSpeed({ speedData, timeInterval }) {
  function formatDate(date, timeInterval) {
    switch (timeInterval) {
      case 'hour':
        return d3.timeFormat("%H:%M")(date);
      case 'day':
        return d3.timeFormat("%d/%m %H:%M")(date);
      case 'week':
        return d3.timeFormat("%a %d/%m")(date);
      case 'month':
      case '3months':
      case '6months':
      case 'year':
        return d3.timeFormat("%d/%m/%Y")(date);
      default:
        return d3.timeFormat("%d/%m/%Y %H:%M")(date);
    }
  }

  function getBarWidth(interval) {
    switch (interval) {
      case 'hour':
        return 30;
      case 'day':
        return 15;
      case 'week':
        return 25;
      case 'month':
        return 15;
      case '3months':
      case '6months':
      case 'year':
        return 25;
      default:
        return 15;
    }
  }

  useEffect(() => {
    if (speedData && Object.keys(speedData).length > 0) {
      createChart("graph", speedData, timeInterval);
    }
  }, [speedData, timeInterval]);

  if (!speedData || Object.keys(speedData).length === 0) {
    return null;
  }

  function createChart(containerId, speedData, timeInterval) {
    const margin = { top: 35, right: 20, bottom: 85, left: 60 },
          width = 1100 - margin.left - margin.right,
          height = 500 - margin.top - margin.bottom;

    d3.select(`#${containerId}`).selectAll("*").remove();

    const svg = d3.select(`#${containerId}`)
      .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
      .append("g")
        .attr("transform",
              `translate(${margin.left},${margin.top})`);

    const data = Object.keys(speedData).map((key) => ({
      date: new Date(key),
      ...speedData[key],
    }));

    const x = d3.scaleBand()
      .range([0, width])
      .domain(data.map(d => d.date))
      .paddingInner(1)
      .paddingOuter(.5);

    const y = d3.scaleLinear()
      .domain([0, d3.max(data, d => d.maximumAvg)])
      .range([height, 0]);

    svg.append("g")
       .attr("transform", `translate(0, ${height})`)
       .call(d3.axisBottom(x).tickFormat(d => formatDate(d, timeInterval)).tickValues(data.map(d => d.date)))
       .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-65)");

    svg.append("g")
       .call(d3.axisLeft(y));

    svg.append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - margin.left)
      .attr("x",0 - (height / 2))
      .attr("dy", "2em")
      .style("text-anchor", "middle")
      .style("font-size", "12px")
      .style("fill", "#696969")
      .text("Vehicle Speed (km/h)");

      
    // Tooltip
    const tooltip = d3.select(`#${containerId}`).append("div")
                      .style("opacity", 0)
                      .attr("class", "tooltip")
                      .style("background-color", "white")
                      .style("border", "solid")
                      .style("border-width", "2px")
                      .style("border-radius", "5px")
                      .style("padding", "5px")
                      .style("position", "absolute")
                      .style("align-items", "left")
                      .style("text-align", "left");

    const showTooltip = (event, date, median, minimumAvg, maximumAvg, percentile25, percentile75, timeInterval) => {
      const tooltipEstimatedWidth = 90;
      const tooltipEstimatedHeight = 90;
      
      let baseX = x(date);
      let baseY = y(median);

      let tooltipX = baseX + getBarWidth(timeInterval) / 2 + tooltipEstimatedWidth;
      let tooltipY = baseY + tooltipEstimatedHeight; 

      // console.log(tooltipX, width);

      if(tooltipX + tooltipEstimatedWidth > width) tooltipX = baseX - getBarWidth(timeInterval) - tooltipEstimatedWidth - 20;

      tooltip
        .style("opacity", 1)
        .html(`<b>Date:</b> ${formatDate(date, timeInterval)}<br>
              <b>Median:</b> ${median.toFixed(2)} km/h<br>
              <b>Min:</b> ${minimumAvg.toFixed(2)} km/h<br>
              <b>Max:</b> ${maximumAvg.toFixed(2)} km/h<br>
              <b>25th Percentile:</b> ${percentile25.toFixed(2)} km/h<br>
              <b>75th Percentile:</b> ${percentile75.toFixed(2)} km/h`)
        .style("left", `${tooltipX}px`) 
        .style("top", `${tooltipY}px`); 
    };

    const hideTooltip = () => {
      tooltip.transition().duration(200).style("opacity", 0);
    };

    const barWidth = getBarWidth(timeInterval);

    // Adding boxplots
    data.forEach(d => {
      const date = d.date;
      const median = d.median;
      const minimumAvg = d.minimumAvg;
      const maximumAvg = d.maximumAvg;
      const percentile25 = d.percentile25;
      const percentile75 = d.percentile75;

      // Line from min to max
      svg.append("line")
         .attr("x1", x(d.date))
         .attr("x2", x(d.date))
         .attr("y1", y(d.minimumAvg))
         .attr("y2", y(d.maximumAvg))
         .attr("stroke", "black")
         .style("cursor", "pointer")
         .on("mouseover", (event, d) => showTooltip(event, date, median, minimumAvg, maximumAvg, percentile25, percentile75, timeInterval))
         .on("mousemove", (event, d) => showTooltip(event, date, median, minimumAvg, maximumAvg, percentile25, percentile75, timeInterval))
         .on("mouseleave", hideTooltip);

      // Box
      svg.append("rect")
         .attr("x", x(d.date) - barWidth / 2)
         .attr("y", y(d.percentile75))
         .attr("height", y(d.percentile25) - y(d.percentile75))
         .attr("width", barWidth)
         .attr("stroke", "black")
         .style("fill", "#69b3a2")
         .style("cursor", "pointer")
         .on("mouseover", (event, d) => showTooltip(event, date, median, minimumAvg, maximumAvg, percentile25, percentile75, timeInterval))
         .on("mousemove", (event, d) => showTooltip(event, date, median, minimumAvg, maximumAvg, percentile25, percentile75, timeInterval))
         .on("mouseleave", hideTooltip);

      // Median line
      svg.append("line")
         .attr("x1", x(d.date) - barWidth / 2)
         .attr("x2", x(d.date) + barWidth / 2)
         .attr("y1", y(d.median))
         .attr("y2", y(d.median))
         .attr("stroke", "black")
         .style("cursor", "pointer")
         .on("mouseover", (event, d) => showTooltip(event, date, median, minimumAvg, maximumAvg, percentile25, percentile75, timeInterval))
         .on("mousemove", (event, d) => showTooltip(event, date, median, minimumAvg, maximumAvg, percentile25, percentile75, timeInterval))
         .on("mouseleave", hideTooltip);

      // Min and Max lines
      const linePadding = barWidth / 2;
      svg.append("line")
          .attr("x1", x(d.date) - linePadding)
          .attr("x2", x(d.date) + linePadding)
          .attr("y1", y(d.minimumAvg))
          .attr("y2", y(d.minimumAvg))
          .attr("stroke", "black")
          .style("cursor", "pointer");


      svg.append("line")
          .attr("x1", x(d.date) - linePadding)
          .attr("x2", x(d.date) + linePadding)
          .attr("y1", y(d.maximumAvg))
          .attr("y2", y(d.maximumAvg))
          .attr("stroke", "black")
          .style("cursor", "pointer");

    });
  
  }

  return (
    <>
      <style>
        {`
          #graph svg * {
            cursor: default;
          }
        `}
      </style>
      <div id="graph" style={{ width: "1000px", height: "520px" }}></div>
    </>
  );
}

export default ChartAvgSpeed;


