import { useEffect, useState, useContext } from 'react';
import styled from "styled-components";
import denm_images from '../../utils/resources'
import road_events from '../../utils/road_events'
import { BsQuestionCircleFill } from "react-icons/bs";
import { Tooltip, TooltipText } from '../Sidebar/Tooltip'
import { AuthContext } from '../Auth/AuthContext';

function UpdateForm(props) {
  const [denm, setDenm] = useState({  cause: props.alert.cause_code, 
                                      subCause: props.alert.sub_cause_code, 
                                      lat: props.alert.latitude, 
                                      long: props.alert.longitude, 
                                      duration: props.alert.validity_duration, 
                                      station_id: props.alert.station_id})
  const [selectAlertImg, setAlertImg] = useState(true);
  const [validInput, setValidInput] = useState({ duration: true, latitude: true, longitude: true })
  const [day, setDay] = useState(0)
  const [hour, setHour] = useState(0)
  const [min, setMin] = useState(0)
  const [second, setSecond] = useState(0)
  const [zoomLevel, setZoomLevel] = useState(18);
  const { isAuthenticated } = useContext(AuthContext);


  useEffect(() => {
    // Calculate days, hours, minutes, and seconds from the total duration in seconds
    let totalSeconds = denm.duration;
    const secondsInMinute = 60;
    const secondsInHour = 3600;
    const secondsInDay = 86400;

    const days = Math.floor(totalSeconds / secondsInDay);
    totalSeconds %= secondsInDay;

    const hours = Math.floor(totalSeconds / secondsInHour);
    totalSeconds %= secondsInHour;

    const minutes = Math.floor(totalSeconds / secondsInMinute);
    totalSeconds %= secondsInMinute;

    const seconds = totalSeconds;

    // Update state variables with calculated values
    setDay(days);
    setHour(hours);
    setMin(minutes);
    setSecond(seconds);
  }, [denm.duration]);
  
  useEffect(() => {
    // Check if the denm.cause is not empty and set the alert image as selected
    if (denm.cause) {
      setAlertImg(true);
      // setDenm((prevDenm) => ({
      //   ...prevDenm,
      //   cause: props.alert.cause,
      //   subCause: props.alert.sub_cause_code,
      // }));
    }
  }, [denm.cause, denm.subCause]);

  useEffect(() => {
    setDenm((prevDenm) => {
      return { ...prevDenm, ...{ lat: props.pinCoordinates.latitude, long: props.pinCoordinates.longitude } }
    })
  }, [props.pinCoordinates.latitude, props.pinCoordinates.longitude])

  
  const validForm = () => {
    let valid = true;
    if ((day + hour + min + second === 0) || (day && hour && min && second < 0)) {
      setValidInput(prevValue => ({ ...prevValue, duration: false }));
      valid = false;
    } else {
      setValidInput(prevValue => ({ ...prevValue, duration: true }));
    }
    if (denm.lat === "") {
      setValidInput(prevValue => ({ ...prevValue, latitude: false }));
      valid = false;
    } else {
      setValidInput(prevValue => ({ ...prevValue, latitude: true }));
    }
    if (denm.long === "") {
      setValidInput(prevValue => ({ ...prevValue, longitude: false }));
      valid = false;
    }
    else {
      setValidInput(prevValue => ({ ...prevValue, longitude: true }));
    }
    return valid;
  }

  const cancelButton = (e) => {
    e.preventDefault();
    setAlertImg(false);
    props.cancelUpdate();
  }

  const updateButton = (e) => {
    e.preventDefault();
    setAlertImg(false);

    if (!validForm()) return

    const day_to_secs = Number(day * 60 * 60 * 24)
    const hours_to_secs = Number(hour * 60 * 60)
    const min_to_secs = Number(min * 60)
    const secs = Number(second)

    const generation_timestamp = Date.now();
    const delta_time = (day_to_secs + hours_to_secs + min_to_secs + secs) * 1000
    const deadline_time = generation_timestamp + delta_time

    const alert = {
        alert_id: props.alert._id,
        station_id: props.alert.station_id,
        latitude: denm.lat * 10e6,
        longitude: denm.long * 10e6,
        station_type: props.alert.station_type,
        cause_code: parseInt(denm.cause),
        sub_cause_code: parseInt(denm.subCause),
        validity_duration: delta_time,
        sub_cause_name: road_events[denm.cause]["subCauseCodes"][denm.subCause]["name"]["en"],
        alert_deadline: deadline_time,
        sequenceNumber: props.alert.sequenceNumber,
        zoom_level: zoomLevel,
    }

    props.submitUpdateAlert(alert);
  }

  return (
    isAuthenticated ? (
      <Form>
        <Image>
          {Object.keys(road_events).map((cause) => (
            <ParentDiv
              key={cause}
              onClick={() => {
                setAlertImg(true);
                setDenm({
                  ...denm,
                  ...{ cause: cause, subCause: 0 },
                });
              }}
              selected={denm.cause == cause}
            >
              <img
                style={{ width: '70px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                src={denm_images[road_events[cause]["subCauseCodes"]['0']['imageCode']]}
                alt="error"
              />
              <label><b>{road_events[cause]["subCauseCodes"]['0']['name']['en']}</b></label>
            </ParentDiv>
          ))}
        </Image>

        {denm.cause && selectAlertImg &&
          <>
            <div className="field">
              <div className="labelText">
                <label>Sub-cause:</label>
              </div>
              <div className="input">
                <select className="inputInner"
                  id="subtype"
                  onChange={() => {
                    setDenm({ ...denm, subCause: document.getElementById("subtype").selectedIndex });
                  }
                  }>
                  {Object.keys(road_events).map(
                    (cause) => Object.keys(road_events[cause]["subCauseCodes"]).map(
                      (subCause) => cause === denm.cause ?
                        <option key={`${cause}${subCause}`}> {road_events[cause]["subCauseCodes"][subCause]["name"]["en"]}</option> : null
                    )
                  )}

                </select>
              </div>
            </div>

            <div className="field">
              <div className="labelText">
                <label>Days</label>
                <div className="inputDuration">
                  <input type="number" placeholder={"days"} className="inputData" onChange={(e) => setDay(e.target.value)} value={day}></input>
                </div>
              </div>
              <div className="labelText">
                <label>Hours</label>
                <div className="inputDuration">
                  <input type="number" placeholder={"hours"} className="inputData" onChange={(e) => setHour(e.target.value)} value={hour}></input>
                </div>
              </div>
              <div className="labelText">
                <label>Minutes</label>
                <div className="inputDuration">
                  <input type="number" placeholder={"minutes"} className="inputData" onChange={(e) => setMin(e.target.value)} value={min}></input>
                </div>
              </div>
              <div className="labelText">
                <label>Seconds</label>
                <div className="inputDuration">
                  <input type="number" placeholder={"seconds"} className="inputData" onChange={(e) => setSecond(e.target.value)} value={second}></input>
                </div>
              </div>
            </div>

            <div className="tooltip">
              <Tooltip>
                <BsQuestionCircleFill />
                <TooltipText>Click on the map to set position</TooltipText>
              </Tooltip>
            </div>

            <div className="grid">

              <div className="rightSideContent">
              </div>

              <div className="field">
                <div className="labelText">
                  <label>Latitude:</label>
                </div>
                <div className="input">
                  <input type="number" className="inputInner" onChange={(e) => { setDenm({ ...denm, lat: e.target.value }) }} value={denm.lat}></input>
                </div>
              </div>

              <div className="field">
                <div className="labelText">
                  <label>Longitude:</label>
                </div>

                <div className="input">
                  <input type="number" className="inputInner" onChange={(e) => { setDenm({ ...denm, long: e.target.value }) }} value={denm.long}></input>
                </div>
              </div>
            </div>

            <div className="field">
              <div className="labelText">
                <label>Zoom Level: </label>
              </div>
              <div className="input">
                <select
                  className="inputInner"
                  value={zoomLevel}
                  onChange={(e) => setZoomLevel(e.target.value)}
                >
                  {Array.from({ length: 23 }, (_, i) => i).map(level => (
                    <option key={level} value={level}>
                      {level}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            
            <ErrorMessage valid={validInput.duration}>
              Insert a valid value for Duration
            </ErrorMessage>

            <ErrorMessage valid={validInput.longitude}>
              Insert a valid value for Longitude
            </ErrorMessage>

            <ErrorMessage valid={validInput.latitude}>
              Insert a valid value for Latitude
            </ErrorMessage>

            <div className="buttons">
              <button className="cancelButton" onClick={cancelButton}>Cancel</button>
              <button className="submitButton" onClick={updateButton}>Submit</button>
            </div>
          </>
        }
      </Form>
    ) : null
  );
}

export default UpdateForm;

const ErrorMessage = styled.div`
        display: ${props => props.valid ? "none" : "block"};
        color: red;
        text-align: start;
        font-size: 0.9rem;
        margin-bottom: 5px;
`

const Form = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding: 0.30rem;

  .field {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        width: 100%;
        height: 27px;
        margin-bottom: 20px;
  }

  .tooltip {
        display: flex;
        margin-bottom: 5px;
      
  }
  .inputDuration{
    flex-basis: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    .inputData{
      width: 100%;
      height: 100%;
    }
  }
  .input {
        flex: 1 1 50px;
        .inputInner {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
        }
  }

  .labelText {
    white-space: nowrap;
    text-align: center;
    font-size: 1rem;
    color: black;
    width: 90px;
    margin-right: 5px;
    align-items: center;
  }

  .grid {
        display:grid;
        grid-template-columns: 1fr fit-content(35%);
        align-items: center;
    }
        
  .grid > .field:nth-child(3){
      grid-row: 2;
  }

  .rightSideContent {
      grid-column: 2;
      grid-row: 1 / 3;

  }
  .buttons{
    display: flex;
    justify-content: space-evenly;
  }
  .cancelButton{
    border-radius: 4px;
    background-color: white; 
    color: black; 
    border: 2px solid #f44336;
    transition-duration: 0.4s;
    &:hover{
      cursor: pointer;
      background-color: #f44336;
      color: white;
    }
  }
  
  .submitButton{
    border-radius: 4px;
    background-color: white; 
    color: black; 
    border: 2px solid #008CBA;
    transition-duration: 0.4s;
    &:hover{
      cursor: pointer;
      background-color: #008CBA;
      color: white;
    }
  }
`
const Image = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border: 2px solid black;
  max-height: 300px;
  overflow: auto;
  margin-bottom: 20px;
  
`
const ParentDiv = styled.div`
    padding: 5px;
    display: flex;
    flex-direction: column;
    background-color: ${props => props.selected ? "black" : ""};
    &:hover {
        background-color: grey;
        cursor: pointer;
  }
  label {
    padding: 0;
    margin: 0;
    font-size: 0.7rem;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
`
