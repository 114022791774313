import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  authenticated: false,
  admin: false
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    set: (state, {payload: user}) => {
        localStorage.setItem('authenticated', true);
        state.authenticated = true;
        localStorage.setItem('admin', user.admin);
        state.admin = user.admin;
    },
    unsetAuth: (state) => {
      state.authenticated = false;
      state.admin = false;
      localStorage.removeItem('authenticated');
      localStorage.removeItem('admin');
    },
  }
});

export const { set, unsetAuth } = authSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAuth = (state) => state.auth.authenticated;
export const selectAdmin = (state) => state.auth.admin;

export default authSlice.reducer;
