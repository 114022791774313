import { useEffect } from 'react';

const DashArrayAnimation = ({ map, rsuList, camList, bsmList, selectedCPM }) => {
    const dashArraySequence = [
      [0, 4, 3],
      [0.5, 4, 2.5],
      [1, 4, 2],
      [1.5, 4, 1.5],
      [2, 4, 1],
      [2.5, 4, 0.5],
      [3, 4, 0],
      [0, 0.5, 3, 3.5],
      [0, 1, 3, 3],
      [0, 1.5, 3, 2.5],
      [0, 2, 3, 2],
      [0, 2.5, 3, 1.5],
      [0, 3, 3, 1],
      [0, 3.5, 3, 0.5]
    ];
  
  
    const updateInterval = 50; // Update dash array every 250 milliseconds
    let step = 0;
    let lastUpdateTime = 0;
  
    function updateDashArrayCam(cam) {
      const matchingRsuList = rsuList.filter((rsu) => rsu._id === cam.rsu_stationId);
  
      if (matchingRsuList.length > 0) {
        matchingRsuList.forEach((rsu) => {
          map.setPaintProperty(`line-dashed-layer-cam-${cam.stationId}-${rsu._id}`, 'line-dasharray', dashArraySequence[step]);
        });
      }
    }
  
    function updateDashArrayBsm(bsm) {
      const matchingRsuList = rsuList.filter((rsu) => rsu._id === bsm.rsu_stationId);
      
      if (matchingRsuList.length > 0) {
        matchingRsuList.forEach((rsu) => {
          map.setPaintProperty(`line-dashed-layer-bsm-${bsm.stationId}-${rsu._id}`, 'line-dasharray', dashArraySequence[step]);
        });
      }
    }

    function updateDashArrayCpm(cpm) {
      map.setPaintProperty(`line-dashed-layer-cpm-${cpm.cpm_id}`, 'line-dasharray', dashArraySequence[step]);
    }
  
    function animateDashArray(timestamp) {
      const elapsedTime = timestamp - lastUpdateTime;
  
      if (elapsedTime > updateInterval) {
        camList.forEach((cam) => updateDashArrayCam(cam));
        bsmList.forEach((bsm) => updateDashArrayBsm(bsm));
        selectedCPM && updateDashArrayCpm(selectedCPM);
        step = (step + 1) % dashArraySequence.length;
        lastUpdateTime = timestamp;
      }
  
      requestAnimationFrame(animateDashArray);
    }
  
    useEffect(() => {
      let animationId;
  
      if (map) {
        animationId = requestAnimationFrame(animateDashArray);
      }
  
      return () => {
        if (animationId) {
          cancelAnimationFrame(animationId);
        }
      };
    }, [map]);
  
    return null;
  };

export default DashArrayAnimation;
