import styled from "styled-components";

export const PopupStyle = styled.div`
    h1 {
        font-size: 1.25rem;
        margin: 10px;
        cursor: text;
    }
    p {
        margin:5px;
        cursor: text;
    }
`
