import React from 'react'
import { Marker } from 'react-map-gl';
import { FaMapMarkerAlt } from "react-icons/fa";

function PinPoint(props) {
    const onDrag = (e) => {
        props.onDrag({ latitude: e.lngLat[1], longitude: e.lngLat[0] })
    }

    return (
        <Marker
            latitude={props.lat}
            longitude={props.long}
            draggable={true}
            onDragEnd={onDrag}
            offsetLeft={-17}
            offsetTop={-34}>
            <FaMapMarkerAlt size={36} style={{ color: props.markerColor}} />
        </Marker> 
    )
}

export default PinPoint
