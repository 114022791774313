import React, { useState } from 'react';
import styled from 'styled-components';
import GenericDropdown from './GenericDropdown'; 

const optionsMapping = {
  "motorcycle": 2,
  "passengerCar": 3,
  "lightTruck": 5,
  "heavyTruck": 6
};


const DropdownObjClass = ({ onSelectChange, resetDropdown }) => {
  const options = ["", ...Object.keys(optionsMapping)];
  const [selectedOption, setSelectedOption] = useState('');

  // Handler to process the selection change
  const handleSelectChange = (selectedLabel) => {
    setSelectedOption(selectedLabel);
    // Use the optionsMapping to convert the label back to its corresponding key
    const selectedKey = optionsMapping[selectedLabel];
    onSelectChange(selectedKey); // Pass the key instead of the label
  };

  return (
    <Container>
      <GenericDropdown
        label={"Object Class"}
        options={options}
        onSelectChange={handleSelectChange}
        selected={selectedOption}
        resetDropdown={resetDropdown}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

export default DropdownObjClass;

