import React, { useEffect } from 'react';

const Embed_Page = ({url}) => {
  const handleScroll = () => {
    window.scrollTo({
      top: window.scrollY + 1,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <iframe
        src={url}
        width="100%"
        height="100%"
        style={{ border: 'none' }}
        title="Embedded Page"
      />
    </div>
  );
};

export default Embed_Page;
