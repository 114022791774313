import { Nav } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

const styles = {
    marginBottom: 10,
};

const CustomNav = ({ active, onSelect, ...props }) => {
    return (
        <Nav {...props} activeKey={active} onSelect={onSelect} style={styles}>
            <Nav.Item eventKey="TollInfo">
                Tolling Info
            </Nav.Item>
            <Nav.Item eventKey="Transactions">
                Transactions
            </Nav.Item>
        </Nav>
    );
};

export default CustomNav;