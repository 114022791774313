import React, { useEffect } from 'react';
import * as d3 from 'd3';

function ChartMeteorology({ containerId, meteorologyData, meteorology, timeInterval }) {
    function formatDate(date, timeInterval) {
        switch (timeInterval) {
        case 'hour':
            return d3.timeFormat("%H:%M")(date);
        case 'day':
            return d3.timeFormat("%d/%m %H:%M")(date);
        case 'week':
            return d3.timeFormat("%a %d/%m")(date);
        case 'month':
        case '3months':
        case '6months':
        case 'year':
            return d3.timeFormat("%d/%m/%Y")(date);
        default:
            return d3.timeFormat("%d/%m/%Y %H:%M")(date);
        }
    }
    
    useEffect(() => {
        if (!meteorologyData || Object.keys(meteorologyData).length === 0) return;

        const svgContainer = d3.select(`#${containerId}`);
        svgContainer.selectAll("*").remove(); // Clear previous SVG elements

        const margin = { top: 35, right: 20, bottom: 85, left: 60 };
        const width = 1100 - margin.left - margin.right;
        const height = 400 - margin.top - margin.bottom;
        const parseTime = d3.isoParse;
        // const formatTime = d3.timeFormat("%d/%m/%Y %H:%M");

        const data = Object.entries(meteorologyData).map(([key, value]) => ({
            time: parseTime(key),
            value: +value
        }));

        // Color mapping based on meteorology type
        const colorMap = {
            temperature: "#F40009",
            humidity: "dodgerblue",
            solar_radiation: "orange",
            precipitation: "deepskyblue",
            wind_speed: "darkblue",
            wind_direction: "purple",
            pressure: "brown",
        };
        const color = colorMap[meteorology] || "deepskyblue"; 

        const yAxisTitleMap = {
            temperature: "Temperature (°C)", 
            humidity: "Humidity (%)", 
            solar_radiation: "Solar Radiation (W/m²)", 
            precipitation: "Precipitation (mm)", 
            wind_speed: "Wind speed (m/s)", 
            wind_direction: "Wind direction (º)",
            pressure: "Atmospheric Pressure (hPa)", 
        };
        const yAxisTitle = yAxisTitleMap[meteorology] || "Value";

        // Create SVG canvas
        const svg = svgContainer
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);

        const xScale = d3.scaleBand()
            .range([0, width])
            .domain(data.map(d => d.time))
            .paddingInner(1)
            .paddingOuter(.5);

        const yScale = d3.scaleLinear()
            .domain([0, d3.max(data, d => d.value)])
            .range([height, 0]);

        // Define the line
        const line = d3.line()
            .x(d => xScale(d.time))
            .y(d => yScale(d.value))
            .curve(d3.curveMonotoneX); 

        // Append the path, bind the data, and call the line generator 
        svg.append("path")
            .datum(data)
            .attr("fill", "none")
            .attr("stroke", color)
            .attr("stroke-width", 1.5)
            .attr("d", line);

        // Add the X Axis
        svg.append("g")
            .attr("transform", `translate(0,${height})`)
            // .call(d3.axisBottom(xScale).tickFormat(d3.timeFormat("%b %d")));
            .call(d3.axisBottom(xScale).tickFormat(d => formatDate(d, timeInterval)).tickValues(data.map(d => d.time)))
            .selectAll("text")
                .style("text-anchor", "end")
                .attr("dx", "-.8em")
                .attr("dy", ".15em")
                .attr("transform", "rotate(-65)");

        // Add the Y Axis
        svg.append("g")
            .call(d3.axisLeft(yScale));

        svg.append("text")
            .attr("transform", "rotate(-90)")
            .attr("y", 0 - margin.left)
            .attr("x", 0 - (height / 2))
            .attr("dy", "2em")
            .style("text-anchor", "middle")
            .style("font-size", "12px")
            .style("fill", "#696969")
            .text(yAxisTitle);

        // Tooltip setup
        const tooltip = d3.select(`#${containerId}`).append("div")
            .attr("class", "tooltip")
            .style("opacity", 0)
            .style("background-color", "white")
            .style("border", "solid")
            .style("border-width", "2px")
            .style("border-radius", "5px")
            .style("padding", "5px")
            .style("position", "absolute");

        // Tooltip functions
        const showTooltip = (event, d) => {
            const tooltipEstimatedWidth = 70;
            const tooltipEstimatedHeight = 150;

            let baseX = xScale(d.time);
            let baseY = yScale(d.value);
            let tooltipX = baseX + tooltipEstimatedWidth;
            let tooltipY = baseY + tooltipEstimatedHeight; 

            if(tooltipX + tooltipEstimatedWidth > width) tooltipX = baseX - tooltipEstimatedWidth - 30;

            const tooltipHtml = `${meteorology.charAt(0).toUpperCase() + meteorology.slice(1)}: ${d.value.toLocaleString('hi-IN')}
                ${meteorology === "temperature" ? "ºC" : ""}
                ${meteorology === "humidity" ? "%" : ""}
                ${meteorology === "solar_radiation" ? "W/m²" : ""}
                ${meteorology === "precipitation" ? "mm" : ""}
                ${meteorology === "wind_speed" ? "m/s" : ""}
                ${meteorology === "wind_direction" ? "º" : ""}
                ${meteorology === "pressure" ? "hPa" : ""}`;


            tooltip
                .style("opacity", 1)
                .html(tooltipHtml)
               
                .style("left", `${tooltipX}px`)
                .style("top", `${tooltipY}px`);
        };

        const hideTooltip = () => {
            tooltip.style("opacity", 0);
        };

        // Add points for each data entry and interact with tooltip
        svg.selectAll(".data-point")
            .data(data)
            .enter()
            .append("circle")
            .attr("class", "data-point")
            .attr("cx", d => xScale(d.time))
            .attr("cy", d => yScale(d.value))
            .attr("r", 3.5)
            .attr("fill", color)
            .on("mouseover", (event, d) => showTooltip(event, d))
            .on("mouseout", hideTooltip);

    }, [meteorologyData, meteorology, timeInterval, containerId]);

    return <div id={containerId} style={{ height: "400px", width: "1000px" }}></div>;
}

export default ChartMeteorology;
        