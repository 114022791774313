import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// function formatDate(dateString) {
//     const date = new Date(dateString);
//     const day = date.getDate().toString().padStart(2, '0');
//     const month = (date.getMonth() + 1).toString().padStart(2, '0'); // JavaScript months are 0-based.
//     const year = date.getFullYear();
//     const hours = date.getHours().toString().padStart(2, '0');
//     const minutes = date.getMinutes().toString().padStart(2, '0');
//     return `${day}/${month}/${year} ${hours}:${minutes}`;
// }


function formatDate(date, timeInterval) {
    switch (timeInterval) {
        case 'hour':
            return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
        case 'day':
            return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
        case 'week':
            return `${date.toLocaleDateString('en-US', { weekday: 'short' })} ${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}`;
        case 'month':
        case '3months':
        case '6months':
        case 'year':
            return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
        default:
            const day = date.getUTCDate().toString().padStart(2, '0');
            const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
            const year = date.getUTCFullYear();
            const hours = date.getUTCHours().toString().padStart(2, '0');
            const minutes = date.getUTCMinutes().toString().padStart(2, '0');
            return `${day}/${month}/${year} ${hours}:${minutes}`;
    }
}


function ChartNumberOfVehicles({ inOutflow, timeInterval }) {
    if (!inOutflow) {
        return null;
    }

    const labels = Object.keys(inOutflow);
    const inflowData = labels.map(label => inOutflow[label]?.inflow || 0);
    const outflowData = labels.map(label => inOutflow[label]?.outflow || 0);

    const options = {
        responsive: true,
        scales: {
            x: {
                type: 'category',
                labels: labels.map(label => formatDate(new Date(label), timeInterval)),
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Vehicle Count'
                }
            },
        },
        plugins: {
            legend: {
                position: 'bottom',
            }
        },
    };

    const data = {
        labels: labels.map(label => formatDate(new Date(label), timeInterval)), 
        datasets: [
            {
                label: 'Inflow',
                data: inflowData,
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
            {
                label: 'Outflow',
                data: outflowData,
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
        ],
    };

    return (
        <>
            <Bar options={options} data={data} style={{padding: '10px'}}/>
        </>
    );
}

export default ChartNumberOfVehicles;

