import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

function TollingZonesForRsu({ tamList, rsuId, handleClick }) {
  const [filteredTams, setFilteredTams] = useState([]);
  const [expandedIndexes, setExpandedIndexes] = useState([]);

  useEffect(() => {
    const filtered = tamList.filter(tam => tam.rsu_stationId === rsuId);
    setFilteredTams(filtered);

    if (Array.isArray(filtered) && filtered.length > 0) {
      const initialExpandedState = Array(filtered.length).fill(false);
      setExpandedIndexes(initialExpandedState);
    }
  }, [rsuId]);

  const toggleExpand = index => {
    const updatedExpandedIndexes = [...expandedIndexes];
    updatedExpandedIndexes[index] = !updatedExpandedIndexes[index];
    setExpandedIndexes(updatedExpandedIndexes);
  };

  function parseDate(obj) {
    const date = new Intl.DateTimeFormat('pt-PT', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    }).format(obj);
    return date;
  }

  return (
    <div>
      {filteredTams.length > 0 ? (
        filteredTams.map((tam, index) => (
          <TollingZoneContainer key={index} highlighted={expandedIndexes[index]}>
            <TitleContainer>
              <Title>
                Tolling Zone {index + 1} ({tam.tollChargesKey})
              </Title>
              <ButtonContainer>
                <NavigateButton onClick={() => handleClick(tam, index)}>
                  Navigate To
                </NavigateButton>
                <ExpandButton onClick={() => toggleExpand(index)}>
                  {expandedIndexes[index] ? <FaChevronUp /> : <FaChevronDown />}
                </ExpandButton>
              </ButtonContainer>
            </TitleContainer>
            {expandedIndexes[index] && (
              <Details>
                <b>Toll Charger ID: </b>{tam.tollChargerInfo.tollChargerId}
                <br />
                <b>Toll Point ID: </b>{tam.tollChargerInfo.tollPointId}
                <br />
                <b>Descriptive Name: </b>{tam.tollChargerInfo.descriptiveName}
                <br />
                <b>Reference Point: </b>{tam.referencePoint.lat / 10000000}, {tam.referencePoint.long / 10000000}
                <br />
                <b>Toll Zone Lanes: </b>
                <ul>
                  {tam.tollZoneLanes.map((lane, laneIdx) => (
                    <li key={laneIdx}>Lane ID: {lane.laneID}</li>
                  ))}
                </ul>
                <b>Approach Lanes: </b>
                <ul>
                  {tam.approachLanes.map((lane, laneIdx) => (
                    <li key={laneIdx}>Lane ID: {lane.laneID}</li>
                  ))}
                </ul>
              </Details>
            )}
          </TollingZoneContainer>
        ))
      ) : (
        <div>No tolling zones associated with this RSU.</div>
      )}
    </div>
  );
}

const TollingZoneContainer = styled.div`
  padding: 10px;
  border: 1px solid #ccc;
  margin: 5px;
  transition: background-color 0.3s ease;
  animation: ${props => (props.highlighted ? pulseAnimation : 'none')};
  background-color: ${props => (props.highlighted ? '#f0f0f0' : 'initial')};
  text-align: left;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-size: 1.1em;
  font-weight: bold;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const NavigateButton = styled.button`
  margin-right: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const ExpandButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  display: flex;
  align-items: center;
`;

const Details = styled.div`
  margin-top: 10px;
`;

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
`;

export default TollingZonesForRsu;
