import { Nav } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

const styles = {
    marginBottom: 10,
};

const CustomNav = ({ active, onSelect, ...props }) => {
    return (
        <Nav {...props} activeKey={active} onSelect={onSelect} style={styles}>
            <Nav.Item eventKey="Heartbeats">
                Heartbeats
            </Nav.Item>
            <Nav.Item eventKey="History">
                History
            </Nav.Item>
            <Nav.Item eventKey="Tolling">
                Toll Zones
            </Nav.Item>
        </Nav>
    );
};

export default CustomNav;