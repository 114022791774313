import React, { useContext, useState } from 'react';
import '../styles/generic_menu.css';
import '../styles/constants.css';
import v2x_background from '../static/background2.jpg';
import { AuthContext } from '../components/Auth/AuthContext';
import Cookies from 'js-cookie';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const ApiToken_Page = () => {
  const { isAuthenticated, isUser } = useContext(AuthContext);
  const [token, setToken] = useState(null);

  const showToken = () =>{ 
    const token = Cookies.get('token');
    setToken(token);
  }

  const hideToken = () =>{ 
    setToken(null);
  }

  return (
    <div className="scrollable-background" style={{ backgroundImage: `url(${v2x_background})` }}>
      <div className="white-box-content large-info">
        <h1>
            API TOKEN
        </h1>
        <p>
            For safety reasons the CCAM API is protected by a token that is generated when the user is logged in.
        </p>
        {(isAuthenticated || isUser ) ? 
            <div> {token ?    
              <div> 
                  <button className="blue-button" onClick={hideToken}>Hide Token</button> 
                  <CopyToClipboard text={token} onCopy={(text, result) => console.log(result)}>
                      <button className="blue-button" >Copy Token</button>
                  </CopyToClipboard>
                  <p class="copyable-text">{token}</p>
              </div>
              : 
                <button className="blue-button" onClick={showToken}>Show Token</button> }
            </div>
        : null}
      </div>
    </div>
  );
};

export default ApiToken_Page;
